import React from "react";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { ResetNewForm } from "./ResetNewForm/ResetNewForm";
export const ResetNew = () => {
  return (
    <>
      <FormLayout
        headerTitle={"Employee Reset password "}
        subTitle={"Reset password here fill the details"}
        component={<ResetNewForm />}
      />
    </>
  );
};
