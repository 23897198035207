import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EditProfileForm } from "./EditProfileFrom/EditProfileForm";
import { getGroupProfile } from "../../../Services/GetUser/GetUser";
import { toast } from "react-toastify";
import { Loader } from "../../../Component/Loader/Loader";

export const EditProfile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getGroupProfile()
      .then((response) => {
        setProfileData(response.data.data);
        setLoading(false);
      })
      .catch((errMsg) => {
        toast.error(errMsg.response.data.message);
        console.log(errMsg.response.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex">
            <p
              className="pe-1"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/profile")}
            >
              Profile
            </p>
            {"/"}
            <p className="add ps-1">Edit Profile</p>
          </div>
          <h4>Edit Group Profile</h4>
          <div className="col-12 mt-1">
            {!loading ? (
              <div className="edit-container">
                {profileData && <EditProfileForm profileData={profileData} />}
              </div>
            ) : (
              <div className="loader">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
