import React from 'react'
import NotFound from '../../Assets/Images/not-found.svg';
import './error.css'
export const ErrorPage = () => {
    return (
        <section>
            <div className="container-fluid">
                <div className="row bg-white">
                    <div className="col-12  not-found">
                        <img src={NotFound} />
                        <h1 className="mt-4">We've lost this page</h1>
                        <p>The requested resource could not be found. Please make sure the URL is correct and try again</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
