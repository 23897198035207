import React from "react";
import moment from "moment";
import { Table } from "../../../../../../Component/Table/Table";

export const EmployeeTable = ({ data }) => {
  //Time formatter
  const PunchFormate = (cell, row) => {
    if (cell === null) {
      return (
        <div className="name-container" key={row.user_id}>
          <span>
            <strong>{"-----"}</strong>
          </span>
        </div>
      );
    }
    return (
      <div className="name-container" key={row.user_id}>
        <span>
          <strong>{moment(cell).local().format("h:mm A")}</strong>
        </span>
      </div>
    );
  };

  //All columns of table
  const columns = [
    {
      dataField: "check_in",
      text: "Punched In",
      formatter: PunchFormate,
    },
    {
      dataField: "check_out",
      text: "Punched Out",
      formatter: PunchFormate,
    },
  ];

  return (
    <>
      <Table EntryData={data?.entries} columns={columns} />
    </>
  );
};
