import React, { useEffect, useState, useRef } from "react";
import send from "../../../../Assets/Images/send.svg";
import defaultImage from "../../../../Assets/Images/default-img.png";
import moment from "moment";
import "./chatRoom.css";
import { Loader } from "../../../../Component/Loader/Loader";
import { chat_socket_event } from "../../../../Services/Socket";
import { useNavigate } from "react-router-dom";

export const ChatRoom = ({
  selectedData,
  socketConnection,
  chatData,
  isLoading,
}) => {
  const [messageInput, setMessageInput] = useState("");
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false);

  const chatContainerRef = useRef();
  const userData = localStorage.getItem("user");
  const profileData = userData ? JSON.parse(userData) : userData;
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftKeyPressed(false);
      }
    };
    let preChatDate = null;
    if (JSON.stringify(chatData) !== JSON.stringify(preChatDate)) {
      preChatDate = chatData;
      socketConnection.emit(chat_socket_event.is_read_update_web, selectedData.user_id, {
        is_read: true,
      });
    

    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

  }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [chatData]);

  const handleSubmit = () => {
    if (messageInput) {
      socketConnection.emit(
        chat_socket_event.send_chat_web,
        selectedData?.user_id,
        messageInput
      );
      setMessageInput("");
    }
  };

  const handleSend = (event) => {
    if(event.target.value !== " "){
      setMessageInput(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!isShiftKeyPressed) {
        handleSubmit();
        event.preventDefault();
      }
    }
  };

  return (
    <div className="col-8 ps-0" key={selectedData?.user_id}>
      <div className="bg-white p-4 mt-2 message-employee">
        <div className="emp-message-panel-bg">
          <div className="chat-header  d-flex align-items-center">
            <div className="chat-header-img">
              {selectedData.upload_photo ? (
                <img src={selectedData.upload_photo} alt="" />
              ) : (
                <img src={defaultImage} alt="" />
              )}
            </div>
            <div className="emp-chat-detail" onClick={()=>navigate(`/employee/employeeProfile/${selectedData?.user_id}`)}>
              <h6 className="p-0 m-0">{selectedData.name}</h6>
            </div>
          </div>
          {!isLoading ? (
            <div className="user-message-box" ref={chatContainerRef}>
              {chatData.length > 0 ? (
                <div className="user-chat-message-container">
                  {chatData.map((item, index) => {
                    if (
                      profileData?.user_id === item.receiver_id &&
                      selectedData?.user_id === item.sender_id
                    ) {
                      return (
                        <div key={`user-${item.chat_id}-${index}`}>
                          <div className="user-chat-message mt-2">
                            <p className="p-0 m-0">{item.message}</p>
                          </div>
                          <p>
                            {moment(item.created_at)
                              .local()
                              .format("hh:mm A, D MMM YY")}
                          </p>
                        </div>
                      );
                    } else {
                      if (
                        profileData?.user_id === item.sender_id &&
                        selectedData?.user_id === item.receiver_id
                      ) {
                        return (
                          <div
                            className="my-message-box"
                            key={`my-${item.chat_id}-${index}`}
                          >
                            <div className="my-message-chat">
                              <p className="p-0 m-0">{item.message}</p>
                            </div>
                            <p>
                              {moment(item.created_at)
                                .local()
                                .format("hh:mm A, D MMM YY")}
                            </p>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              ) : (
                <div className="empty-message-container">
                  <div>
                    <h6>No communication</h6>
                    <p>Say hi to {selectedData.name}</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="chat-loader-container">
              <Loader />
            </div>
          )}

          <div className="message-input-box mb-3">
            <textarea
              onKeyPress={handleKeyPress}
              className="form-control text-area-size"
              placeholder="Write a message"
              type="text"
              value={messageInput}
              onChange={handleSend}
              autoFocus
              rows={4}
            />
            <button className="btn p-2" onClick={handleSubmit}>
              <img src={send} alt="send" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
