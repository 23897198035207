import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { getUserProfile } from "../../../../Services/GetUser/GetUser";
import { Loader } from "../../../../Component/Loader/Loader";
import edit from "../../../../Assets/Images/edit-1.svg";
import defaultImage from "../../../../Assets/Images/default-img.png";
import "./employeeProfile.css";
import moment from "moment";

export const EmployeeProfile = () => {
  const navigate = useNavigate();
  const userParams = useParams();
  const [profileData, setProfileData] = useState();
  const [loading, setLoading] = useState(true);

  //Get user details form user profile api
  useEffect(() => {
    getUserProfile(userParams?.user_id)
      .then((response) => {
        setProfileData(response.data.data[0]);
        setLoading(false);
      })
      .catch((errMsg) => console.log(errMsg));
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="inner-contain">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex">
                  <p
                    className="me-1"
                    onClick={() => navigate("/employee")}
                    style={{ cursor: "pointer" }}
                  >
                    Employee
                  </p>
                  {"/"}
                  <p className="employeeProfile-component ms-1">
                    Employee Profile
                  </p>
                </div>
                <div className="employee-edit-section">
                  <h4>Employee Profile</h4>
                  <Button
                    className="edit-button-1"
                    onClick={() =>
                      navigate(`/employee/editEmployee/${userParams?.user_id}`)
                    }
                  >
                    <img src={edit} />
                    Update
                  </Button>
                </div>
              </div>

              <div className=" col-12 mt-2">
                <div className=" bg-section">
                  <div className="mb-4">
                    <div className="cover-photo">
                      <div className="profile-header-contain">
                        <div className="profile-img">
                          {profileData?.upload_photo ? (
                            <img
                              src={profileData?.upload_photo}
                              alt="upload Image"
                            />
                          ) : (
                            <img src={defaultImage} alt="default Image" />
                          )}
                        </div>
                        <div className="profile-button w-100">
                          <h5 className="mx-3">{profileData?.name}</h5>
                          <Button
                            className="edit-button"
                            onClick={() =>
                              navigate(
                                `/employee/editEmployee/${userParams?.user_id}`
                              )
                            }
                          >
                            <img src={edit} />
                            Update
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile-employee-info">
                    <h4>Information</h4>
                    <hr className="hr-border" />
                    <div className="row d mt-4">
                      <div className="col-12 col-sm-6 col-md-4 mt-2">
                        <label htmlFor="email" className="employee-label">
                          Email ID
                        </label>
                        <h6>{profileData?.email}</h6>
                      </div>
                      <div className=" col-6 col-md-4 mt-2">
                        <label htmlFor="dob" className="employee-label">
                          Date of Birth
                        </label>
                        <h6>{moment(profileData?.dob).format("MM-DD-YYYY")}</h6>
                      </div>
                      <div className="col-6 col-md-4 mt-2">
                        <label
                          htmlFor="mobile Number"
                          className="employee-label"
                        >
                          Mobile Number
                        </label>
                        <h6>{profileData?.phone}</h6>
                      </div>
                      <div className="col-6 col-md-4 mt-2">
                        <label htmlFor="pinCode" className="employee-label">
                          Gender
                        </label>
                        <h6>{profileData?.gender}</h6>
                      </div>
                      <div className="col-6 col-md-4 mt-2">
                        <label htmlFor="address" className="employee-label">
                          Address
                        </label>
                        <h6>{profileData?.address}</h6>
                      </div>
                      <div className="col-md-4 col-6">
                        <label htmlFor="status" className="employee-label">
                          Status
                        </label>
                        <h6>{profileData?.status}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
