import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "../../Component/Header/Header";
import { Sidebar } from "../../Component/Sidebar/Sidebar";

export const Dashboard = () => {
  const [toggle, setToggle] = useState(false);
  const handleClick = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <Header handleOnClick={handleClick} status={toggle} />
      <div className={`container-view ${toggle ? "openSidebar" : ""}`}>
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
};
