import React from "react";
import success_icon from "../../../Assets/Images/success-icon.svg";
import "./success.css";
export const Success = () => {
  return (
    <section className="background-successfully">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="reset-successfully-bg">
              <img src={success_icon} />
              <h1>Password Successfully Changed </h1>
              <p>
                You have successfully changed your account password. your can
                now sign in to start using you application{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
