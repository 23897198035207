import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { forgotApi } from "../../../../Services/AuthApi/Forgot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

import { useDispatch } from "react-redux";
import { setEmail } from "../../../../Redux/UserSlice";
import "./forgotForm.css";
import { InputField } from "../../../../Component/InputField/InputField";

export const ForgotFrom = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const errorNotify = (msg) => toast.error(msg);
  const successNotify = (msg) => toast.success(msg);
  const dispatch = useDispatch();

  const initialValue = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .matches(/\.com$/, "Email must end with .com")
      .required("Email is required"),
  });

  const onSubmit = (values) => {
    setLoading(true);
    forgotApi(values)
      .then((response) => {
        successNotify(response.data.message);
        dispatch(setEmail(values.email));
        navigate("/otp");
      })
      .catch((error) => {
        console.log(error);
        errorNotify(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <div>
              <h1>Email Verification</h1>
              <p>Enter your email address to verify</p>
            </div>
            <div className="input-container">
              <InputField
                label="Email"
                name="email"
                placeholder="email"
                type="email"
              />
            </div>
            <div className="button-container">
              <Button type="submit" disabled={loading}>
                {loading && (
                  <Spinner
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {loading ? " loading... " : "SEND"}
              </Button>
            </div>
            <div className="inner-container mt-3">
              <p className="mb-0">Already have an account?</p>
              <h6 className="ms-2 mb-0" onClick={() => navigate("/")}>
                Login
              </h6>
            </div>
            <ToastContainer />
          </Form>
        );
      }}
    </Formik>
  );
};
