import React from "react";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { ForgotFrom } from "./ForgotForm/ForgotForm";
export const Forgot = () => {
  return (
    <FormLayout
      headerTitle={"Email Verification"}
      subTitle={"Enter your valid email address"}
      component={<ForgotFrom />}
    />
  );
};
