import React, { useEffect, useState } from "react";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { OtpFrom } from "./OtpForm/OtpForm";
import { useSelector } from "react-redux";
import { ErrorPage } from "../../../Component/ErrorPage/ErrorPage";

export const Otp = () => {

  const userEmail = useSelector((state) => state.User.email);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userEmail]);

  return (
    <>
      {show ? (
        <FormLayout
          headerTitle={"Otp verification"}
          subTitle={"Please enter your valid otp"}
          component={<OtpFrom userEmail={userEmail} />}
        />
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
