import React from "react";
import default_image from "../../../../Assets/Images/default-img.png"
import empty_chat from "../../../../Assets/Images/chat.svg";

const ChatWelcome = () => {
  const userData = localStorage.getItem("user");
  const profileData = userData ? JSON.parse(userData) : userData;

  return (
    <div className="container-fluid bg-white mt-2" key={profileData?.user_id}>
      <div className="row">
        <div className="col-12">
          <div className="welcome-profile d-flex">
            <div className="welcome-group">
              {profileData?.logo_url ? <img src={profileData?.logo_url} alt="logo" /> : <img src={default_image} alt="logo"/>}
            </div>
            <div className="welcome-text ms-3">
              <h5 className="p-0 m-0 ">Welcome!</h5>
              <h1 className="p-0 m-0">{profileData?.group_name}</h1>
            </div>
          </div>
          <div className="empty-page">
            <img src={empty_chat} alt="welcome-profile" />
            <h3 className="p-0 m-0">It’s nice to chat with someone</h3>
            <p>Pick a person from left menu and start your conversation</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWelcome;
