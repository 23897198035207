import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "./attendance.css";
import { AttendanceTable } from "./AttendanceTable/AttendanceTable";
import { getAllUserEntryApi } from "../../../Services/EntryApi";
import { Loader } from "../../../Component/Loader/Loader";
import { AttendanceGrid } from "../../../Component/AttendanceGrid/AttendanceGrid";
import employee from "../../../Assets/Images/employee_1x.svg";
import { useDispatch } from "react-redux";
import { setDate } from "../../../Redux/UserSlice";
import moment from "moment";

export const Attendance = () => {
  const [entryData, setEntryData] = useState();
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState();
  const [entryDate, setEntryDate] = useState(new Date());
  const dispatch = useDispatch();

  //Get all all users in out entries from userAllEntry api
  useEffect(() => {
    const startDate = moment(entryDate).local().startOf('day').format()
    const endDate = moment(entryDate).local().endOf('day').format()
    getAllUserEntryApi({ start_date: startDate,end_date:endDate})
      .then((response) => {
        setEntryData(response.data.data);
        setTotalData(response.data.details);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [entryDate]);

  useEffect(()=>{
    dispatch(setDate(""))
  },[])

  const handleDate = (value) => {
    if (value === null) {
      //setEntryDate(new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
      setEntryDate(moment().startOf('day').format())
      return value;
    }
    //setEntryDate(new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
    setEntryDate(moment(value).startOf('day').format())
    //dispatch(setDate(new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })));
    dispatch(setDate(moment(value).startOf('day').format()))
  };

  console.log("entry",entryDate);
  

  return (
    <div className="inner-contain">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 ">
              <h4>Employees Attendance</h4>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                title="Total Employee"
                grid_icon={employee}
                total={totalData?.total_users.toString().padStart(2, "0")}
                backgroundColor={{ backgroundColor: "#D3F3FF" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                title="Total Punched-In"
                grid_icon={employee}
                total={totalData?.punched_in_users.toString().padStart(2, "0")}
                backgroundColor={{ backgroundColor: "#E3F49A" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                title="Total Punched-Out"
                grid_icon={employee}
                total={totalData?.punched_out_users.toString().padStart(2, "0")}
                backgroundColor={{ backgroundColor: "#EBDFFF" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                title="Total Inactive Employee"
                grid_icon={employee}
                total={totalData?.inactive_users.toString().padStart(2, "0")}
                backgroundColor={{ backgroundColor: "#FFD6D6" }}
              />
            </div>
            <div className="col-12 pt-4 pb-4">
              <div className="bg-white p-4 rounded-3 shadow-container">
                <div className="d-flex flex-row-reverse entry-date">
                  <div className="employee-date-container">
                    <DatePicker
                      format="MM-dd-y"
                      value={entryDate}
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      onChange={handleDate}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                {entryData ? (
                  <AttendanceTable EntryData={entryData} />
                ) : (
                  <div className="bottom-container ">
                    <h6>No Data found</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
