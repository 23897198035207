import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserListApi } from "../Services/GetUser/GetUser";
const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});
export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
    email: "",
    date: "",
    notificationData : []
  },
  reducers: {
    addEmployee: (state, action) => {
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    },
    removeEmployee: (state, action) => {
      const employeeId = action.payload;
      const newData = state.data.filter((item) => item.user_id !== employeeId);
      return {
        ...state,
        data: newData,
      };
    },
    editEmployee: (state, action) => {
      //console.log("action",action.payload)
      const editData = state.data.map((item) =>
        item.user_id === action.payload.user_id ? action.payload : item
      );
      //console.log("edit",editData);
      return {
        ...state,
        data: editData,
      };
    },

    setEmail: (state, action) => {
      state.email = action.payload;
    },

    deleteEmail: (state) => {
      state.email = "";
    },

    setDate: (state, action) => {
      state.date = action.payload;
    },
    setNotification:(state,action)=>{
      state.notificationData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const {
  setUser,
  setStatus,
  addEmployee,
  removeEmployee,
  editEmployee,
  setEmail,
  deleteEmail,
  setDate,
  setNotification
} = userSlice.actions;
export default userSlice.reducer;

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (params, thunkAPI) => {
    try {
      const response = await getUserListApi();
      //console.log("response",response.data.data)
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
