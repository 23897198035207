import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { getUserEntryApi } from "../../../../../Services/EntryApi";
import { Loader } from "../../../../../Component/Loader/Loader";
import clock from "../../../../../Assets/Images/clock.svg";
import { EmployeeTable } from "./EmployeeTable/EmployeeTable";
import date from "../../../../../Assets/Images/calendar.svg";
import { AttendanceGrid } from "../../../../../Component/AttendanceGrid/AttendanceGrid";
import "./employeeAttendance.css";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../../../../Redux/UserSlice";

export const EmployeeAttendance = () => {
  const userId = useParams();
  const [userEntry, setUserEntry] = useState([]);
  const [loading, setLoading] = useState(true);
  const select_date = useSelector((state) => state.User.date);
  console.log("date",select_date);
  const selectedDate = select_date === "" ? new Date() : select_date;
  const [entryDate, setEntryDate] = useState(selectedDate);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Get particular employee entries from getUserEntry api
  useEffect(() => {
    const startDate = moment(entryDate).local().startOf('day').format()
    const endDate = moment(entryDate).local().endOf('day').format()
    getUserEntryApi({ user_id: userId.id, start_date: startDate,end_date:endDate })
      .then((response) => {
        setUserEntry(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [userId.id, entryDate]);

  const handleDate = (value) => {
    if (value === null) {
      // setEntryDate(
      //   new Date().toLocaleDateString("en-US", {
      //     month: "2-digit",
      //     day: "2-digit",
      //     year: "numeric",
      //   })
      // );
      setEntryDate(moment().startOf('day').format())
      return value;
    }
    // setEntryDate(
    //   new Date(value).toLocaleDateString("en-US", {
    //     month: "2-digit",
    //     day: "2-digit",
    //     year: "numeric",
    //   })
    // );
    setEntryDate(moment(value).startOf('day').format())
  };

  const handleNavigation = () => {
    dispatch(setDate(""));
    navigate("/attendance");
  };

  return (
    <div className="inner-contain">
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex">
              <p
                className="px-1"
                style={{ cursor: "pointer" }}
                onClick={handleNavigation}
              >
                Employees Attendance
              </p>
              {"/"}
              <p className="add px-1">Employee Attendance Details</p>
            </div>
            <div className="col-12">
              <h4>Employee Attendance Details</h4>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                grid_icon={date}
                title="Date"
                total={moment(userEntry.date).format("MM-DD-YYYY")}
                backgroundColor={{ backgroundColor: "#D3F3FF" }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <AttendanceGrid
                grid_icon={clock}
                title="Total Time"
                total={userEntry.total_time}
                backgroundColor={{ backgroundColor: "#D3F3FF" }}
              />
            </div>
            <div className="col-12 pt-4 pb-4">
              <div className="bg-white p-4 rounded-3 shadow-container">
                <div className="d-flex flex-row-reverse">
                  <div className="employee-date-container">
                    <DatePicker
                      format="MM-dd-y"
                      value={entryDate}
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      onChange={handleDate}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                {userEntry ? (
                  <div>
                    <EmployeeTable data={userEntry} />
                  </div>
                ) : (
                  <div className="bottom-container">
                    <h6>No Data found</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
