import React, { useEffect, useState } from "react";
import { ResetFrom } from "./ResetForm/ResetFrom";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { useSelector } from "react-redux";
import { ErrorPage } from "../../../Component/ErrorPage/ErrorPage";
export const Reset = () => {
  
  const userEmail = useSelector((state) => state.User.email);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  return (
    <>
      {show ? (
        <FormLayout
          headerTitle={"Reset password now"}
          subTitle={"Please reset password here"}
          component={<ResetFrom userEmail={userEmail} />}
        />
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
