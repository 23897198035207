import React from "react";
import { useNavigate } from "react-router-dom";

import "./addEmployee.css";
import { AddForm } from "./AddForm/AddForm";

export const AddEmployee = () => {
  const navigate = useNavigate();

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex">
            <p
              className="px-1"
              onClick={() => navigate("/employee")}
              style={{ cursor: "pointer" }}
            >
              Employee
            </p>
            {"/"}
            <p className="add px-1">Add new employee</p>
          </div>
          <h4>Add New Employee</h4>
          <p className="notice mb-0">
            Please fill the information to add the employee in the system
          </p>
          <div className="col p-3">
            <div className="row add-container">
              <div className="col-12 p-4">
                <AddForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
