import React, { useEffect, useState } from "react";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { LoginForm } from "./LoginForm/LoginForm";
import {getMessaging} from "firebase/messaging/sw";
import {getToken} from "firebase/messaging"

export const Login = () => {
  const messaging = getMessaging();
  const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_KEY;
  const [fireBaseToken, setFireBaseToken] = useState();
  useEffect(() => {
    getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          console.log("token",currentToken);
          setFireBaseToken(currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }, []);

  return (
    <FormLayout
      headerTitle={"Get Started Group"}
      subTitle={"Welcome, Please login to your account"}
      component={<LoginForm fireBaseToken={fireBaseToken} />}
    />
  );
};
