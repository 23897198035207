import { Field, Formik, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import DatePicker from "react-date-picker";
import moment from "moment";
import "./addFrom.css";
import { registerUserApi } from "../../../../../Services/User/User";
import { addEmployee } from "../../../../../Redux/UserSlice";
import { genderData, statusData } from "../../../../../Services/Constant";
import { ImageInput } from "../../../../../Component/ImageInput/ImageInput";
import {
  InputField,
  PasswordField,
} from "../../../../../Component/InputField/InputField";

export const AddForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialValue = {
    name: "",
    email: "",
    password: "",
    phone: "",
    gender: "",
    address: "",
    dob: "",
    status: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the name"),
    email: Yup.string().email().required("Enter the email"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    phone: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Enter the address"),
    dob: Yup.string().required("Select date of birth"),
    status: Yup.string().required("Select status"),
    gender: Yup.string().required("Select Gender"),
  });

  const checkImageValidation = (imageObj) => {
    if (imageObj && imageObj.size > 5000000) {
      setImageError(
        "Please ensure that the image you are uploading is less than 5MB in size"
      );
    } else {
      setImageError("");
      setImageUrl(imageObj);
    }
  };
  
  const onSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("upload_photo", imageUrl);
    //Call register api for register user
    registerUserApi(formData)
      .then((response) => {
        dispatch(addEmployee(response.data.data));
        toast.success(response.data.message);
        navigate("/employee");
      })
      .catch((errMsg) => {
        console.log(errMsg);
        setLoading(false);
        toast.error(errMsg.response.data.message);
      });
  };
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        console.log("value",values.phone)
        return (
          <FormFormik>
            <div className="row">
              <div class="col-12 col-sm-6 col-lg-4">
                <InputField
                  label="Name"
                  name="name"
                  placeholder="name"
                  type="text"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <InputField
                  label="Email"
                  name="email"
                  placeholder="email"
                  type="email"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <div className="date-picker-container">
                  <label className="floating-label">Date of Birth</label>
                  <DatePicker
                    className="date-picker-class"
                    format="MM-dd-y"
                    dayPlaceholder="DD"
                    value={values.dob}
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    maxDate={new Date()}
                    autoFocus={false}
                    onChange={(value) => {
                      value === null
                        ? setFieldValue("dob", "")
                        : setFieldValue(
                            "dob",
                            moment(value).format("YYYY-MM-DD")
                          );
                    }}
                  />
                  <ErrorMessage
                    name="dob"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <FloatingLabel controlId="floatingSelect" className="mb-3">
                  <div className="select-search add-emp-form">
                    <Select
                      options={genderData}
                      placeholder="Gender"
                      autoFocus={false}
                      value={
                        values.gender
                          ? genderData.find(
                              (options) => options.value === values.gender
                            )
                          : ""
                      }
                      onChange={(option) =>
                        setFieldValue("gender", option.value)
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="gender"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <FloatingLabel controlId="floatingSelect" className="mb-3">
                  <div className="select-search add-emp-form">
                    <Select
                      options={statusData}
                      placeholder="Status"
                      autoFocus={false}
                      value={
                        values.status
                          ? statusData.find(
                              (options) => options.value === values.status
                            )
                          : ""
                      }
                      onChange={(option) =>
                        setFieldValue("status", option.value)
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="status"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </FloatingLabel>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <PasswordField
                  label="Password"
                  name="password"
                  placeholder="Password"
                  showPassword={showPassword}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone Number"
                  className="mb-3"
                >
                  <Field name="phone">
                    {({ field }) => (
                      <InputMask  mask="(999) 999-9999" {...field}>
                        {() => (
                          <Form.Control
                            autoComplete="off"
                            type="text"
                            placeholder="Phone Number"
                            {...field}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                  <ErrorMessage
                    name="phone"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </FloatingLabel>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <InputField
                  label="Address"
                  name="address"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className="col-4 d-none d-lg-block"></div>
              <div className="col-12 col-sm-6 col-md-4">
                <ImageInput
                  imageUrl={imageUrl}
                  handleImageClear={() => setImageUrl("")}
                  checkImageValidation={checkImageValidation}
                />
              </div>
              <div className="errorMessage mt-2">{imageError}</div>
              <div className="col-12">
                <div className="d-flex mt-4 flex-row-reverse">
                  <Button
                    variant="primary"
                    type="submit"
                    className="px-4 mx-1 add-button"
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {loading ? " loading.... " : "Submit"}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="px-4 mx-1"
                    onClick={() => navigate("/employee")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ToastContainer />
          </FormFormik>
        );
      }}
    </Formik>
  );
};
