export const socket_url = "https://trackerapp-socket.sofmen.com";
export const local_socket_url = "http://192.168.50.180:5002"

//socket connection events name
export const socket_connection_event = {
  connect: "connect",
  disconnect: "disconnect",
  connection_error: "connect_error",
};

//chat socket events name
export const chat_socket_event = {
  chat_socket_connection: "chat",
  users_list_request: "users_data_web",
  get_users_list: "users_list_web",
  receiver_notification_web: "receiver_notification_web",
  count_receiver_notification_web: "count_receiver_notification_web",
  count_seen_chat: "count_seen_chat",
  count_chat_for_receiver: "count_chat_for_receiver",
  is_open_update_web: "is_open_update_web",
  receiver_chat: "receiver_chat",
  find_users_latest_message: "find_users_latest_message",
  sender_chat: "sender_chat",
  received_old_chat_web: "received_old_chat_web",
  is_read_update_web: "is_read_update_web",
  load_old_chat_web: "load_old_chat_web",
  send_chat_web: "send_chat_web",
  count_is_open: "count_is_open",
  sent_count_is_open: "sent_count_is_open",
  sent_total_chat_count_web: "sent_total_chat_count_web",
  open_count_seen: "open_count_seen",
  count_chat_notification_open: "count_chat_notification_open",
};

//notifications socket events name
export const notification_socket_event = {
  notification_socket_connection: "notification",
  sent_notification_type: "sent_notification_type",
  notification_messages: "notification_messages",
  sent_notification_open: "sent_notification_open",
  sent_notification_read: "sent_notification_read",
  sent_notification__all_delete: "sent_notification__all_delete",
  sent_notification_delete: "sent_notification_delete",
};

//locations socket events name
export const location_socket_event = {
  current_location_socket_connection: "current_location_request",
  history_location_socket_connection: "location_request",
  current_location_update: "current_location_update",
  location:'location',
  locationUpdate:'locationUpdate'
};
