import { ErrorMessage, Form, Formik } from 'formik';
import *as Yup from 'yup';
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import './otp.css'
import OtpInput from 'react-otp-input'
import { useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import { forgotApi } from '../../../../Services/AuthApi/Forgot';
export const OtpFrom = ({userEmail}) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [firstTimer, setFirstTimer] = useState(300);
    const [disabled, setDisabled] = useState(true)
    const errorNotify = (msg) => toast.error(msg);
    const successNotify = (msg) => toast.success(msg);
    

    useEffect(() => {
        let intervalId;
        if (userEmail && firstTimer > 0) {
            intervalId = setInterval(() => {
                setFirstTimer((time) => time - 1);
            }, 1000)
        }
        return () => clearInterval(intervalId)
    }, [userEmail, firstTimer])


    useEffect(() => {
        if (firstTimer === 0) {
            setDisabled(false);
        }
    }, [firstTimer]);

    const initialValue = {
        email: userEmail,
        otp: ''
    }
    const validationSchema = Yup.object({
        otp: Yup.string().required("Enter otp")
    })
    const onSubmit = (values) => {
        //console.log("otpMail",values.email)
        setLoading(true)
        forgotApi(values).then((response) => {
            console.log(response)
            successNotify(response.data.message)
            navigate('/reset')
        }).catch((error) => {
            console.log(error)
            errorNotify(error.response.data.message)
            setLoading(false)
        })
    }

    const onSubmitOtp = () => {
        setFirstTimer(30)
        setDisabled(true)
        forgotApi({ email: userEmail }).then((response) => {
            successNotify(response.data.message)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            errorNotify(error.response.data.message)
            setLoading(false)
        })
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    return (
        <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue }) => {
                //console.log("Otp", values);
                return (<Form>
                    <div>
                        <h1>OTP Verification</h1>
                        <p>To verify your identity, please enter OTP that has been sent to your email address</p>
                    </div>
                    <div className='otp-field-input'>
                        <OtpInput
                            value={values.otp}
                            onChange={(item) => {
                                setFieldValue('otp', item)
                            }}
                            numInputs={4}
                            renderInput={(props) => <input {...props} />}
                        />
                        <ErrorMessage
                            name="otp"
                            render={(errMsg) => (
                                <div className="errorMessage">{errMsg}</div>
                            )}
                        />
                    </div>
                    <div className='resend-otp'>
                        <h6>Time Remaining: {formatTime(firstTimer)}</h6>
                        <Button className='resend-button' disabled={disabled} onClick={onSubmitOtp}>Resend OTP</Button>
                    </div>
                    <div className='button-container'>
                        <Button type='submit'>
                            {loading && <Spinner
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            {loading ? ' loading... ' : 'VERIFY'}
                        </Button>
                    </div>
                    <div className='inner-container mt-3'>
                        <p className='mb-0'>Already have an account?</p>
                        <h6 className='ms-2 mb-0' onClick={() => navigate('/')}>Login</h6>
                    </div>
                    <ToastContainer />
                </Form>)
            }}
        </Formik>
    )
}
