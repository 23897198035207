import React, { useEffect, useState } from "react";
import "./allNotification.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import io from "socket.io-client";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import default_image from "../../../Assets/Images/default-img.png";
import {
  local_socket_url,
  notification_socket_event,
  socket_connection_event,
  socket_url,
} from "../../../Services/Socket";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../Redux/UserSlice";
export const AllNotification = ({ socket, notificationType, show }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [newSocket, setNewSocket] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // const socket = io(local_socket_url, {
    //   transports: ["websocket"],
    //   secure: true,
    //   query: {
    //     Authorization: token,
    //   },
    // });
    if (show) {
      console.log("inner notification server connected");
      setNewSocket(socket);
      socket.emit(notification_socket_event.sent_notification_type, {
        type: notificationType,
      });
      // socket.on(socket_connection_event.connect, () => {
      //   socket.emit(notification_socket_event.notification_socket_connection);
      //   socket.emit("sent_notification_type_inner", {
      //     type: notificationType,
      //   });
      //   console.log("inner notification server connected");
      // });

      //Connection error
      socket.on(socket_connection_event.connection_error, (error) => {
        if (error.data && error.data.code === "TOKEN_NOT_PROVIDED") {
          console.log("Token not provided");
          setLoading(false);
        } else if (error.data && error.data.code === "INVALID_TOKEN") {
          console.log("Invalid token or expired");
          setLoading(false);
        } else {
          console.log("An error occurred during the connection");
          setLoading(false);
        }
      });

      let lastData = null;
      // socket.on(socket_connection_event.disconnect, () => {
      //   console.log("inner notification server disconnected");
      // });

      //list of all notification event
      socket.on(notification_socket_event.notification_messages, (response) => {
        dispatch(setNotification(response.data));
        setLoading(false);
        const currentNotificationData = response.data;
        if (
          JSON.stringify(currentNotificationData) !== JSON.stringify(lastData)
        ) {
          console.log("data not same");
          lastData = currentNotificationData;
          setNotificationData(currentNotificationData);
        }
      });
    }

    socket.on("clear_notification_data",(response)=>{
      setNotificationData(response.data)
    })
    // return () => {
    //   socket.disconnect();
    // };
  }, [notificationType]);

  //remove notification
  const handleRemoveNotification = (message_id, e) => {
    e.stopPropagation();
    newSocket.emit(notification_socket_event.sent_notification_delete, {
      notification_message_id: message_id,
      is_delete: false,
      //type: notificationType,
    });
    newSocket.emit(notification_socket_event.sent_notification_type, {
      type: notificationType,
    });
  };

  return (
    <div className="row">
      {loading ? (
        <div className="loader-container">
          <Spinner animation="border" variant="info" />
        </div>
      ) : (
        <div className="col-12">
          {notificationData.length > 0 ? (
            <div>
              {notificationData &&
                notificationData.map((item) => {
                  return (
                    <div
                      className="notification-list"
                      style={{
                        backgroundColor:
                          item.is_read === false ? "#F9F9F9" : "",
                      }}
                    >
                      <FontAwesomeIcon
                        className="font-class"
                        icon={faCircleXmark}
                        onClick={(e) =>
                          handleRemoveNotification(
                            item.notification_message_id,
                            e
                          )
                        }
                      />
                      <div className="emp-img-notification">
                        {item.upload_photo ? (
                          <img src={item.upload_photo} alt="logo" />
                        ) : (
                          <img src={default_image} alt="logo" />
                        )}
                      </div>
                      <div className="emp-detail-notification">
                        <h6 className="p-0 m-0">{item.name}</h6>
                        <div>
                          <p>{item.message}</p>
                          <p>
                            {moment(item.created_at)
                              .local()
                              .format("MM-DD-YYYY  hh:mm A")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-notification-list">NO NOTIFICATIONS</div>
          )}
        </div>
      )}
    </div>
  );
};
