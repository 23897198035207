import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import add from "../../../Assets/Images/add.svg";
import call from "../../../Assets/Images/call.svg";
import email from "../../../Assets/Images/email.svg";
import dot from "../../../Assets/Images/threedot.svg";
import edit from "../../../Assets/Images/edit.svg";
import remove from "../../../Assets/Images/remove.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import defaultImage from "../../../Assets/Images/default-img.png";
import "./employee.css";
import { PopupModal } from "../../../Component/PopupModal/PopupModal";
import { removeUserApi } from "../../../Services/User/User";
import { fetchUserData, removeEmployee } from "../../../Redux/UserSlice";
import { Loader } from "../../../Component/Loader/Loader";
import { SearchBar } from "../../../Component/SearchBar/SearchBar";

export const Employee = () => {
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserData());
    setLoading(false);
  }, []);

  //Get all user data from redux store

  const data = useSelector((state) => state.User);
  const userData = data.data;

  const handleShow = (userID) => {
    setShow(true);
    setUserId(userID);
  };

  //Delete particular user
  const handleDelete = () => {
    removeUserApi(userId)
      .then((response) => {
        dispatch(removeEmployee(userId));
        toast.success(response.data.message);
        setShow(false);
      })
      .catch((errMsg) => {
        console.log(errMsg);
        toast.error(errMsg.response.data.message);
        setShow(false);
      });
  };

  const handleSearchEmployee = (event) => {
    if (event.target.value !== " ") {
      setEmployeeSearch(event.target.value);
    }
  };

  const filteredData =
    userData &&
    userData.filter((item) => {
      const emp_name = item.name;
      return emp_name.toLowerCase().includes(employeeSearch.toLowerCase());
    });

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row ">
          <div className="pb-3 col-12">
            <h4> Employees </h4>
          </div>
          <div className="col-3 pb-4">
            <SearchBar value={employeeSearch} onChange={handleSearchEmployee} />
          </div>

          <div className="col-9 text-end pb-4">
            <Button
              variant="primary"
              className="p-2 px-md-3 add-employee"
              onClick={() => navigate("/employee/addEmployee")}
            >
              <img src={add} alt="plus" className="me-1 mx-md-2" />
              <span>Add new employee</span>
            </Button>
          </div>
          {!loading ? (
            <>
              {filteredData.length !== 0 ? (
                filteredData.map((item) => {
                  // All employee list
                  return (
                    <>
                      {item.role !== "group_admin" ? (
                        <div
                          className="col-12 col-md-6 col-lg-4 col-xl-3"
                          key={item?.user_id}
                        >
                          <div
                            className="bg-white mb-3 rounded-3"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/employee/employeeProfile/${item?.user_id}`
                              )
                            }
                          >
                            <div className="p-4 py-3 ">
                              <div className="d-flex justify-content-between emp-detail-card">
                                <div className="emp-img">
                                  {item?.upload_photo ? (
                                    <img
                                      src={item?.upload_photo}
                                      alt="upload_photo"
                                    />
                                  ) : (
                                    <img src={defaultImage} alt="default" />
                                  )}
                                </div>
                                <Dropdown onClick={(e) => e.stopPropagation()}>
                                  <Dropdown.Toggle
                                    className="bg-white p-2"
                                    id="dropdown-basic"
                                  >
                                    <img src={dot} alt="dot" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="employee-delete-edit">
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigate(
                                          `/employee/editEmployee/${item?.user_id}`
                                        )
                                      }
                                    >
                                      <img src={edit} className="mb-1 me-1" />{" "}
                                      Update
                                    </Dropdown.Item>
                                    {item?.role === "user" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleShow(item?.user_id)
                                        }
                                      >
                                        <img
                                          src={remove}
                                          className="mb-1 me-1"
                                        />
                                        Remove
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <h6 className=" text-truncate mt-2 employee-name">{item.name}</h6>
                              <p
                                className={
                                  item.status === "Active"
                                    ? "emp-status-active-color"
                                    : "emp-status-inactive-color"
                                }
                              >
                                {item.status}
                              </p>
                            </div>
                            <hr className="hr-border" />
                            <div className="p-2">
                              <div className="employee-detail-box">
                                <img src={call} alt="call" />
                                <h6 className="m-0 ps-2 text-black-50">
                                  {item?.phone}
                                </h6>
                              </div>
                              <div className="employee-detail-box">
                                <img src={email} alt="email" />
                                <h6 className="m-0 ps-2 text-black-50">
                                  {item?.email}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })
              ) : (
                <div className="empty-employee-list">No data found</div>
              )}
            </>
          ) : (
            <div className="loader">
              <Loader />
            </div>
          )}
        </div>
      </div>

      <PopupModal
        modalTitle={"Remove Employee"}
        description={"Are you sure you want to remove employee"}
        show={show}
        handleClose={() => setShow(false)}
        button1={"Cancel"}
        button2={"Remove"}
        handleDelete={handleDelete}
        photo={deleteIcon}
      />
    </div>
  );
};
