import React, { useEffect } from "react";
import { Field, Form as FormFormik, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import defaultImage from "../../../../Assets/Images/default-img.png";
import { editGroupApi } from "../../../../Services/User/User";
import { VerificationModal } from "../../../../Component/PopupModal/VerificationModal/VerificationModal";
import { ImageInput } from "../../../../Component/ImageInput/ImageInput";
import { InputField } from "../../../../Component/InputField/InputField";

export const EditProfileForm = ({ profileData }) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [preImage, setPreImage] = useState("");

  //convert file name to blob object
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imgUrl = profileData?.logo_url
          ? profileData.logo_url
          : defaultImage;
        const response = await fetch(imgUrl);
        const blob = await response?.blob();
        const file = new File([blob], "filename.png", { type: blob.type });
        setPreImage(file);
        setImageUrl(file);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };
    fetchImage();
  }, [profileData.logo_url]);

  const initialValue = {
    name: profileData.group_name || "",
    email: profileData.email || "",
    contact_person_name: profileData.contact_person_name || "",
    phone: profileData.phone || "",
    website_url: profileData.website_url || "",
    address: profileData.address || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Group name is required"),
    email: Yup.string().email().required("Email is required"),
    contact_person_name: Yup.string().required(
      "Contact person name is required"
    ),
    phone: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    website_url: Yup.string().required("Website URL is required"),
  });

  //Check image validation
  const checkImageValidation = (imageObj) => {
    if (imageObj && imageObj.size > 5000000) {
      setImageError(
        "Please ensure that the image you are uploading is less than 5MB in size"
      );
    } else {
      setImageError("");
      setImageUrl(imageObj);
    }
  };

  const handleOk = () => {
    setShowPopUp(false);
    toast.success(successMsg);
    navigate("/profile");
  };

  const onSubmit = (values) => {
    setLoading(true);
    if (
      JSON.stringify(values) !== JSON.stringify(initialValue) ||
      imageUrl !== preImage
    ) {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (imageUrl) {
        if (imageUrl !== preImage) {
          formData.append("logo_url", imageUrl);
          editGroupApi(formData)
            .then((response) => {
              localStorage.setItem("user", JSON.stringify(response.data.data));
              if (response.data.data?.email !== profileData?.email) {
                setLoading(false);
                setSuccessMsg(response.data.message);
                setShowPopUp(true);
              } else {
                setLoading(false);
                toast.success(response.data.message);
                navigate("/profile");
              }
            })
            .catch((errMsg) => {
              console.log(errMsg);
              setLoading(false);
              toast.error(errMsg.response.data.message);
            });
        } else {
          editGroupApi(formData)
            .then((response) => {
              localStorage.setItem("user", JSON.stringify(response.data.data));
              if (response.data.data?.email !== profileData?.email) {
                setLoading(false);
                setSuccessMsg(response.data.message);
                setShowPopUp(true);
              } else {
                setLoading(false);
                toast.success(response.data.message);
                navigate("/profile");
              }
            })
            .catch((errMsg) => {
              console.log(errMsg);
              setLoading(false);
              toast.error(errMsg.response.data.message);
            });
        }
      } else {
        setImageError("Image is required");
        setLoading(false);
      }
    } else {
      if (!imageUrl) {
        setImageError("Image is required");
        setLoading(false);
      } else {
        setLoading(false);
        navigate("/profile");
      }
    }
  };

  console.log("preImage", preImage);
  console.log("current", imageUrl);

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {() => {
          return (
            <FormFormik>
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <InputField
                    label="Name"
                    name="name"
                    placeholder="name"
                    type="text"
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <InputField
                    label="Email"
                    name="email"
                    placeholder="email"
                    type="email"
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Phone Number"
                    className="mb-3"
                  >
                    <Field name="phone">
                      {({ field }) => (
                        <InputMask mask="(999) 999-9999" {...field}>
                          {() => (
                            <Form.Control
                              autoComplete="off"
                              type="text"
                              placeholder="Phone Number"
                              {...field}
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                    <ErrorMessage
                      name="phone"
                      render={(errMsg) => (
                        <div className="errorMessage">{errMsg}</div>
                      )}
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <InputField
                    label="Contact Person"
                    name="contact_person_name"
                    placeholder="Contact person name"
                    type="text"
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <InputField
                    label="Website"
                    name="website_url"
                    placeholder="Website"
                    type="text"
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <InputField
                    label="Address"
                    name="address"
                    placeholder="Address"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <ImageInput
                    imageUrl={imageUrl}
                    checkImageValidation={checkImageValidation}
                    handleImageClear={() => setImageUrl("")}
                  />
                </div>
                <div className="errorMessage mt-2">{imageError}</div>
                <div className="col-12">
                  <div className="d-flex mt-4 flex-row-reverse">
                    <Button
                      variant="primary"
                      type="submit"
                      className="px-4 mx-1 add-button"
                      disabled={loading}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {loading ? " loading.... " : "Submit"}
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className="px-4 mx-1"
                      onClick={() => navigate("/profile")}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </FormFormik>
          );
        }}
      </Formik>
      <VerificationModal show={showPopUp} handleOk={handleOk} />
    </>
  );
};
