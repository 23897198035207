import React from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../Assets/Images/default-img.png";
import "./attendanceTable.css";
import moment from "moment";
import { Table } from "../../../../Component/Table/Table";
import { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export const AttendanceTable = ({ EntryData }) => {
  const navigate = useNavigate();
  const { SearchBar } = Search;
  //Employee name formatter
  const empFormate = (cell, row) => {
    return (
      <div className="name-container" key={row.user_id}>
        <div className="emp-img">
          {row.upload_photo ? (
            <img src={row.upload_photo} alt="upload_photo" />
          ) : (
            <img src={defaultImage} alt="defaultImage" />
          )}
        </div>
        <span
          onClick={() =>
            navigate(`/attendance/employeeAttendance/${row.user_id}`)
          }
        >
          <strong className="d-inline-block employee-name text-truncate" style={{ cursor: "pointer" }}>
            {cell}
          </strong>
        </span>
      </div>
    );
  };

  //Employee name time formatter
  const PunchFormate = (cell, row) => {
    if (cell === null) {
      return (
        <div className="name-container" key={row.user_id}>
          <span>
            <strong>{"-----"}</strong>
          </span>
        </div>
      );
    }
    return (
      <div className="name-container" key={row.user_id}>
        <span>
          <strong>{moment(cell).local().format("h:mm A")}</strong>
        </span>
      </div>
    );
  };

  const dateFormate = (cell, row) => {
    return (
      <div className="name-container" key={row.user_id}>
        <span>
          <strong>{moment(cell).format("MM-DD-YYYY")}</strong>
        </span>
      </div>
    );
  };

  //All columns of table
  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: empFormate,
    },
    {
      dataField: "date",
      text: "Date",
      searchable: false,
      formatter: dateFormate,
    },
    {
      dataField: "first_check_in",
      text: "Punched In",
      searchable: false,
      formatter: PunchFormate,
    },
    {
      dataField: "last_check_out",
      text: "Punched Out",
      searchable: false,
      formatter: PunchFormate,
    },
    {
      dataField: "total_time",
      text: "Total Hours",
      searchable: false,
    },
  ];

  return (
    <>
      <Table EntryData={EntryData} columns={columns} SearchBar={SearchBar} />
    </>
  );
};
