import { createBrowserRouter } from "react-router-dom";
import { Forgot } from "../Pages/Auth/Forgot/Forgot";
import { Login } from "../Pages/Auth/Login/Login";
import { Reset } from "../Pages/Auth/Reset/Reset";
import { Dashboard } from "../Pages/Dashboard";
import { Attendance } from "../Pages/Dashboard/Attendance/Attendance";
import { Chat } from "../Pages/Dashboard/Chat/Chat";
import { AddEmployee } from "../Pages/Dashboard/Employee/AddEmployee/AddEmployee";
import { Employee } from "../Pages/Dashboard/Employee/Employee";
import { EmployeeProfile } from "../Pages/Dashboard/Employee/EmployeeProfile/EmployeeProfile";
import { Location } from "../Pages/Dashboard/Location/Location";
import { EditEmployee } from "../Pages/Dashboard/Employee/EditEmployee/EditEmployee";
import { Profile } from "../Pages/Auth/Profile/Profile";
import { ResetNew } from "../Pages/Auth/ResetNew/ResetNew";
import { AuthProtected } from "./AuthProtected/AuthProtected";
import { DashboardProtected } from "./DashboardProtected/DashboardProtected";
import { Otp } from "../Pages/Auth/Otp/Otp";
import { ResetPassword } from "../Pages/Auth/ResetPassword/ResetPassword";
import { EmployeeAttendance } from "../Pages/Dashboard/Attendance/AttendanceTable/EmployeeAttendance/EmployeeAttendance";
import { Success } from "../Pages/Auth/Success/Success";
import { EditProfile } from "../Pages/Dashboard/EditProfile/EditProfile";
import { ErrorPage } from "../Component/ErrorPage/ErrorPage";
import { EmailSuccess } from "../Pages/Auth/EmailSuccess/EmailSuccess";
export const router = createBrowserRouter([
  {
    element: <AuthProtected />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
      {
        path: "otp",
        element: <Otp />,
      },
      {
        path: "reset",
        element: <Reset />,
      },
    ],
  },
  {
    path: "success",
    element: <Success />,
  },
  {
    path: "verifyEmail",
    element: <EmailSuccess />,
  },
  {
    path: "resetPassword",
    element: <ResetNew />,
  },
  {
    path: "resetPasswordCompany",
    element: <ResetPassword />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    element: <DashboardProtected />,
    children: [
      {
        element: <Dashboard />,
        children: [
          {
            path: "location",
            element: <Location />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "profile/editProfile",
            element: <EditProfile />,
          },
          {
            path: "chat",
            element: <Chat />,
          },
          {
            path: "attendance",
            element: <Attendance />,
          },
          {
            path: "attendance/employeeAttendance/:id",
            element: <EmployeeAttendance />,
          },
          {
            path: "employee",
            element: <Employee />,
          },
          {
            path: "employee/addEmployee",
            element: <AddEmployee />,
          },
          {
            path: "employee/employeeProfile/:user_id",
            element: <EmployeeProfile />,
          },
          {
            path: "employee/editEmployee/:user_id",
            element: <EditEmployee />,
          },
        ],
      },
    ],
  },
]);
