import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";

export const CurrentLocation = ({ data,mapPosition}) => {
  const [isShowInfoBox, setShowInfoBox] = useState(false);
  const [infoBoxDetails, setInfoDetails] = useState();
  //Map center position
  const position = { lat: 22.7251817, lng: 75.8943031 };

  // map size
  const containerStyle = {
    width: "calc(100vw - 615px)",
    height: "calc(100vh - 260px)",
  };

  //Marker popup function
  const handleInfoBox = (userData) => {
    setInfoDetails(userData);
    setShowInfoBox(true);
  };

  return (
    <div className="col-12">
      <div className="map-heading py-2">
        <h5 className="mb-0 pb-0">Employees Location</h5>
      </div>
      <div className="map-wrapper">
      <GoogleMap
        mapContainerClassName="map-iframe mt-2"
        mapContainerStyle={containerStyle}
        center={mapPosition? mapPosition : position}
        zoom={13}
        onClick={() => setShowInfoBox(false)}
        options={{
          streetViewControl: false,
          disableDefaultUI: true,
          zoomControl:true
        }}
      >
        {data &&
          data.map((userData) => {
            const latNum = Number(userData.latitude);
            const lngNum = Number(userData.longitude);
            return (
              <div className="marker-location-img">
                <Marker
                  key={userData.user_id}
                  position={{ lat: latNum, lng: lngNum }}
                  onClick={() => handleInfoBox(userData)}
                >
                  {isShowInfoBox &&
                    infoBoxDetails?.user_id === userData.user_id && (
                      <InfoWindow position={{lat:latNum, lng:lngNum}} onCloseClick={()=>setShowInfoBox(false)}>
                        <h6>{infoBoxDetails?.name}</h6>
                      </InfoWindow>
                    )}
                </Marker>
              </div>
            );
          })}
      </GoogleMap>
      </div>
      
    </div>
  );
};
