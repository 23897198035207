import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.css";
import employees from "../../Assets/Images/employee.svg";
import chat from "../../Assets/Images/chat.svg";
import calendar from "../../Assets/Images/calendar.svg";
import employeeActive from "../../Assets/Images/employee-fill.svg";
import chatActive from "../../Assets/Images/chat-fill.svg";
import work from "../../Assets/Images/work.svg";
import calendarActive from "../../Assets/Images/calendar-fill.svg";
import workActive from "../../Assets/Images/work-fill.svg";
import io from "socket.io-client";
import Badge from "react-bootstrap/Badge";
import {
  chat_socket_event,
  socket_connection_event,
  socket_url,
} from "../../Services/Socket";

export const Sidebar = () => {
  const [newSocket, setNewSocket] = useState(null);
  const [chatNotification, setChatNotification] = useState([]);

  const userData = localStorage.getItem("user");
  const profileData = userData ? JSON.parse(userData) : userData;
  const location = useLocation();
  const isActive = location.pathname === "/chat"

  //socket connection for chat notification
  useEffect(() => {
    const token = localStorage.getItem("token");
    const socket = io(socket_url, {
      transports: ["websocket"],
      secure: true,
      query: {
        Authorization: token,
      },
    });

    socket.on(socket_connection_event.connect, () => {
      socket.emit(chat_socket_event.chat_socket_connection);
      socket.emit(chat_socket_event.count_is_open);
      console.log("chat total notification connection established");
    });

    setNewSocket(socket);
    socket.on(socket_connection_event.connection_error, (error) => {
      if (error.data && error.data.code === "TOKEN_NOT_PROVIDED") {
        console.log("Token not provided");
      } else if (error.data && error.data.code === "INVALID_TOKEN") {
        console.log("Invalid token or expired");
      } else {
        console.log("An error occurred during the connection");
      }
    });

    //events for counting notification
    socket.on(chat_socket_event.sent_count_is_open, (response) => {
      setChatNotification(response.data);
    });

    //event is call when click on chat nav link
    socket.on(chat_socket_event.sent_total_chat_count_web, (response) => {
      //console.log("sent_total_chat_count_web", response);
      setChatNotification(response.data);
    });

    //chat broadcast notification count seen event
    socket.on(chat_socket_event.open_count_seen, (response) => {
      if (response.data[0].sender_id === profileData?.user_id) {
        //console.log("open_count_seen", response);
        setChatNotification(response.data);
      }
    });

    //chat broadcast notification count event
    socket.on(chat_socket_event.count_chat_notification_open, (response) => {
      if (response.data[0].sender_id === profileData?.user_id) {
        //console.log("count_chat_notification_open", response);
        setChatNotification(response.data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className="side-bar" id="side_bar_nav">
      <div className="barContainer">
        <NavLink
          to="location"
          className={({ isActive }) =>
            isActive ? "sidebar-active" : "sidebar-pending"
          }
        >
          {({ isActive }) => (
            <img src={isActive ? employeeActive : employees} alt="employees" />
          )}
        </NavLink>
        <Badge
          bg="danger"
          className="translate-middle badge-siderbar-chat-counter badge-icon"
        >
          {!isActive && profileData?.user_id === chatNotification[0]?.sender_id
            ? chatNotification[0]?.count === 0
              ? ""
              : chatNotification[0]?.count
            : ""}
        </Badge>
        <NavLink
          to="chat"
          className={({ isActive }) =>
            isActive
              ? "sidebar-active"
              : "sidebar-pending chat-notification-sidebar"
          }
          onClick={() => {
            newSocket.emit("is_open_update_web", { is_open: true });
          }}
        >
          {({ isActive }) => (
            <img src={isActive ? chatActive : chat} alt="chat" />
          )}
        </NavLink>

        <NavLink
          to="attendance"
          className={({ isActive }) =>
            isActive ? "sidebar-active" : "sidebar-pending"
          }
        >
          {({ isActive }) => (
            <img src={isActive ? calendarActive : calendar} alt="chat" />
          )}
        </NavLink>

        <NavLink
          to="employee"
          className={({ isActive }) =>
            isActive ? "sidebar-active" : "sidebar-pending"
          }
        >
          {({ isActive }) => (
            <img src={isActive ? workActive : work} alt="chat" />
          )}
        </NavLink>
      </div>
    </div>
  );
};
