import React from 'react'
import Modal from 'react-bootstrap/Modal';
import './popupModal.css'
import Button from 'react-bootstrap/Button';
export const PopupModal = ({ photo, show, handleClose, modalTitle, button1, button2, description, handleDelete }) => {
  return (
    <Modal size='sm' centered={true} show={show} onHide={handleClose}>
      <Modal.Header closeButton style={{ border: 'none' }}></Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col'>
            <div className='modal-view'>
             <div className='icon-view'>
                <img src={photo} alt='photo' />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col'>
            <div className='modal-view'>
              <h4>{modalTitle}</h4>
            </div>
            <h6 className='text-h6'>{description}</h6>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='modal-view mt-2'>
              <Button variant='primary' className='px-4 mx-2 cancel-button' onClick={handleClose} >{button1}</Button>
              <Button className='px-4 mx-2 remove-button-content' onClick={handleDelete}>{button2}</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

