import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";

import { AllNotification } from "./AllNotification/AllNotification";
import "./sideCanvas.css";
import { notification_socket_event } from "../../Services/Socket";
import { useSelector } from "react-redux";

export const SideCanvas = ({ show, handleClose, socket }) => {
  const notificationData = useSelector((state) => state.User.notificationData);
  const [activeTab, setActiveTab] = useState("all");
  const handleRemoveAllNotification = () => {
    socket.emit(notification_socket_event.sent_notification__all_delete, {
      is_all_delete: false,
      notification_message_ids: notificationData?.map(
        (item) => item.notification_message_id
      ),
    });
  };

  return (
    <div className="notification-side-popup">
      <Offcanvas
        placement="end"
        show={show}
        onHide={handleClose}
        className="notification-popup"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification</Offcanvas.Title>
          <Button
            className="clear-notification-button"
            onClick={handleRemoveAllNotification}
          >
            Clear All
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            defaultActiveKey="all"
            id="uncontrolled-tab-example"
            className="mb-3 tab-container"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
          >
            <Tab eventKey="all" title="All">
              {activeTab === "all" && (
                <AllNotification
                  notificationType=""
                  socket={socket}
                  show={show}
                />
              )}
            </Tab>
            <Tab eventKey="punched in" title="Punched In">
              {activeTab === "punched in" && (
                <AllNotification
                  notificationType="punched_in"
                  socket={socket}
                  show={show}
                />
              )}
            </Tab>
            <Tab eventKey="punched out" title="Punched Out">
              {activeTab === "punched out" && (
                <AllNotification
                  notificationType="punched_out"
                  socket={socket}
                  show={show}
                />
              )}
            </Tab>
            <Tab eventKey="location" title="Location">
              {activeTab === "location" && (
                <AllNotification
                  notificationType="location"
                  socket={socket}
                  show={show}
                />
              )}
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
