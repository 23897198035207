import { RouterProvider } from "react-router-dom";
import { router } from "./Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import app from "./firebase";
import "firebase/messaging";
import { useEffect, useState } from "react";
import { getMessaging } from "firebase/messaging";
import { onMessage } from "firebase/messaging";
import "./App.css";
import "./Assets/Css/Custom.css";
import { ErrorPage } from "./Component/ErrorPage/ErrorPage";

function App() {
  const [isOnline, setOnline] = useState(navigator.onLine);
  const messaging = getMessaging();
  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.warn("Notification permission denied.");
        }
      });
    }

    function onlineHandler() {
      setOnline(true);
    }

    function offlineHandler() {
      setOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    onMessage(messaging, (data) => {
      console.log("notification_data",data.data.type);
      const notificationType = data.data.type === "chat";
      const isChatRoute = window.location.pathname === "/chat";
      if (isChatRoute && notificationType) {
        if (isChatRoute && !notificationType) {
          toast.info(
            <div>
              <h6>{data.notification.title}</h6>
              <span
                className="d-inline-block text-truncate"
                style={{ maxWidth: 150 }}
              >
                {data.notification.body}
              </span>
            </div>
          );
        }
      } else {
        toast.info(
          <div>
            <h6>{data.notification.title}</h6>
            <span
              className="d-inline-block text-truncate"
              style={{ maxWidth: 150 }}
            >
              {data.notification.body}
            </span>
          </div>
        );
      }
    });

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <div>
          <ToastContainer />
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </div>
      ) : (
        <ErrorPage />
      )}
    </>
  );
}

export default App;
