import React, { useEffect } from "react";
import success_icon from "../../../Assets/Images/success-icon.svg";
import { emailVerification } from "../../../Services/AuthApi/Reset/Reset";
export const EmailSuccess = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsValue = params.get("token");
  useEffect(() => {
    emailVerification(paramsValue)
      .then((response) => {
        console.log(response);
      })
      .catch((errMsg) => {
        console.log(errMsg);
      });
  }, []);

  return (
    <section className="background-successfully">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="reset-successfully-bg">
              <img src={success_icon} />
              <h1>Email updated successfully</h1>
              <p>
                You have successfully updated your account email. you can now
                sign in to start using your application{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
