import React from "react";
import { Field, ErrorMessage } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const InputField = ({ label, name, placeholder, type }) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label} className="mb-3">
      <Field name={name}>
        {({ field }) => (
          <Form.Control
            autoComplete="off"
            type={type}
            placeholder={placeholder}
            {...field}
            autoFocus={false}
            maxLength={50}
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(errMsg) => <div className="errorMessage">{errMsg}</div>}
      />
    </FloatingLabel>
  );
};

export const PasswordField = ({ showPassword, onClick,label,placeholder,name }) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label} className="mb-3">
      <Field name={name}>
        {({ field }) => (
          <Form.Control
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            {...field}
          />
        )}
      </Field>
      <FontAwesomeIcon
        onClick={onClick}
        icon={showPassword ? faEye : faEyeSlash}
      />
      <ErrorMessage
        name={name}
        render={(errMsg) => <div className="errorMessage">{errMsg}</div>}
      />
    </FloatingLabel>
  );
};
