import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./changePasswordPopUp.css";
import { Button } from "react-bootstrap";
import { changePasswordApi } from "../../../../Services/AuthApi/ChangePassword";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { PasswordField } from "../../../../Component/InputField/InputField";

export const ChangePasswordPopUp = ({ show, close }) => {
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setOldPassword] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const initialValue = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    old_password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    new_password: Yup.string()
      .required("New Password is required")
      .notOneOf(
        [Yup.ref("old_password"), null],
        "New password must not be the same as old password"
      )
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const onSubmit = (values) => {
    setLoading(true);
    changePasswordApi(values)
      .then((response) => {
        if (response.status === 202) {
          setLoading(false);
          toast.error(response.data.message);
        } else {
          setLoading(false);
          toast.success(response.data.message);
          close();
        }
      })
      .catch((errMsg) => {
        console.log(errMsg);
        toast.error(errMsg.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Modal size="md" centered={true} show={show} onHide={close}>
      <Modal.Header closeButton style={{ border: "none" }}>
        <div className="change-password-input">
          <h4>Change Password</h4>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {() => {
                return (
                  <Form>
                    <div className="change-password-input">
                      <PasswordField
                        showPassword={showOldPassword}
                        name="old_password"
                        placeholder="Old Password"
                        label="Old Password"
                        onClick={() => setOldPassword(!showOldPassword)}
                      />
                    </div>
                    <div className="change-password-input">
                      <PasswordField
                        showPassword={showNewPassword}
                        name="new_password"
                        placeholder="New Password"
                        label="New Password"
                        onClick={() => setNewPassword(!showNewPassword)}
                      />
                    </div>
                    <div className="change-password-input">
                      <PasswordField
                        showPassword={showConfirmPassword}
                        name="confirm_password"
                        placeholder="Confirm Password"
                        label="Confirm Password"
                        onClick={() => setConfirmPassword(!showConfirmPassword)}
                      />
                    </div>
                    <div className="change-button-container">
                      <Button type="submit" disabled={loading}>
                        {loading && (
                          <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {loading ? " loading.... " : "Change Password"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
