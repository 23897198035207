import axios from "axios";
export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_KEY,
  //baseURL : "http://192.168.50.180:5000"
});

Api.interceptors.request.use(
  (config) => {
    // console.log("request--",config)
    const token = localStorage.getItem("token");
    if (token !== undefined) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (errMsg) => {
    return Promise.reject(errMsg);
  }
);
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (errMsg) => {
    console.log("error", errMsg.response.data.message);
    if (errMsg.response && errMsg.response.status === 401) {
      localStorage.clear();
      window.location =
        window.location.protocol + "//" + window.location.host + "/";
    }
    return Promise.reject(errMsg);
  }
);
