import React from "react";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { Button } from "react-bootstrap";
import moment from "moment";

import "./historyLocation.css";
import play from "../../../../Assets/Images/play_button.png";
import pause from "../../../../Assets/Images/pause_button.png";
import { Loader } from "../../../../Component/Loader/Loader";
import currentPin from "../../../../Assets/Images/pin-red.svg";
import startPin from "../../../../Assets/Images/pin-yellow.svg";
import endPin from "../../../../Assets/Images/pin-purple.svg";
import { timeMarkerIcon } from "../../../../Assets/Icons";
import progressPin from "../../../../Assets/Images/pin-green.svg"
import { local_socket_url, location_socket_event, socket_connection_event, socket_url } from "../../../../Services/Socket";

export const HistoryLocation = ({ userSelectedData }) => {
  // const start_date = new Date();
  // start_date.setUTCHours(0, 0, 0, 0);
  // const end_date = new Date();
  // end_date.setUTCHours(23, 59, 59, 999);
  const start_date = moment().startOf('day').format();
  const end_date = moment().endOf('day').format();

  const [location, setLocation] = useState([]);
  const [progress, setProgress] = useState(null);
  const [changeId, setChangeId] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [showPlayButton, setShowButton] = useState(true);
  const [selectDate, setSelectDate] = useState([]);
  const [startDate, setStartDate] = useState(start_date)
  const [endDate, setEndDate] = useState(end_date)
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState("");
  const [center, setCenter] = useState({});
  const [newSocket, setNewSocket] = useState(null);
  const [userStatus, setUserStatus] = useState(false);
  const [isHover, setIsHover] = useState("");

  // Clear previous interval and reset state when userSelectedData or date range changes
  useEffect(() => {
    setLoading(true);
    if (changeId) {
      clearInterval(changeId);
      setProgress(null);
      setTime("");
      setIsRunning(false);
    }

    //Get token from local storage
    const token = localStorage.getItem("token");
    const socket = io(socket_url, {
      transports: ["websocket"],
      secure: true,
      query: {
        Authorization: token,
      },
    });

    //Set socket in state for using outside of useEffect
    setNewSocket(socket);

    let lastData = null;
    let user_status = null;

    //Call connect event establish connection to the server
    socket.on(socket_connection_event.connect, () => {
      console.log("connected to the server");
      socket.emit(location_socket_event.history_location_socket_connection);
      socket.emit(location_socket_event.location, {
        user_id: userSelectedData?.user_id,
        from_date: startDate,
        to_date: endDate,
        time:1000
      });
    });

    //Call disconnect event for disconnect connection to the server
    socket.on(socket_connection_event.disconnect, () => {
      console.log("disconnect to the server");
    });

    //Connection error
    socket.on(socket_connection_event.connection_error, (error) => {
      if (error.data && error.data.code === "TOKEN_NOT_PROVIDED") {
        console.log("Token not provided");
        setLoading(false);
      } else if (error.data && error.data.code === "INVALID_TOKEN") {
        console.log("Invalid token or expired");
        setLoading(false);
      } else {
        console.log("An error occurred during the connection");
        setLoading(false)
      }
    });

    //Call location update event and get response from the server
    socket.on(location_socket_event.locationUpdate, (response) => {
      console.log("historyLocation", response);
      socket.emit(location_socket_event.location, {
        user_id: userSelectedData?.user_id,
        from_date: startDate,
        to_date: endDate,
        time:1000
      });

      const currentData = response.data;
      const currentUserStatus = response.currentStatus;

      if (
        JSON.stringify(currentData) === JSON.stringify(lastData) &&
        currentUserStatus === user_status
      ) {
        console.log("Data is the same");
        setUserStatus(currentUserStatus);
        return;
      }

      // Update lastData with currentData
      lastData = currentData;
      user_status = currentUserStatus;

      if (
        startDate === start_date &&
        endDate === end_date
      ) {
        //socket.connect();
        setLocation(currentData);
        setLoading(false);
        setShowButton(true);
        const LetLocation = currentData;
        const CurrentLocation =
          LetLocation && LetLocation[LetLocation.length - 1];
        const CurrentLocationLatLng =
          CurrentLocation && CurrentLocation[CurrentLocation.length - 1];
        const currentLatNum = Number(CurrentLocationLatLng?.latitude);
        const currentLngNum = Number(CurrentLocationLatLng?.longitude);
        setCenter({ lat: currentLatNum, lng: currentLngNum });
      } else {
        if (
          startDate !== start_date &&
          endDate === end_date
        ) {
          //socket.connect();
          setLocation(currentData);
          setLoading(false);
          setShowButton(true);
          const LetLocation = currentData;
          const CurrentLocation =
            LetLocation && LetLocation[LetLocation.length - 1];
          const CurrentLocationLatLng =
            CurrentLocation && CurrentLocation[CurrentLocation.length - 1];
          const currentLatNum = Number(CurrentLocationLatLng?.latitude);
          const currentLngNum = Number(CurrentLocationLatLng?.longitude);
          setCenter({ lat: currentLatNum, lng: currentLngNum });
        } else {
          setLocation(currentData);
          setLoading(false);
          setShowButton(true);
          const LetLocation = currentData;
          const CurrentLocation =
            LetLocation && LetLocation[LetLocation.length - 1];
          const CurrentLocationLatLng =
            CurrentLocation && CurrentLocation[CurrentLocation.length - 1];
          const currentLatNum = Number(CurrentLocationLatLng?.latitude);
          const currentLngNum = Number(CurrentLocationLatLng?.longitude);
          setCenter({ lat: currentLatNum, lng: currentLngNum });
          socket.disconnect();
        }
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [userSelectedData?.user_id, startDate, endDate]);
  
  useEffect(() => {
    setSelectDate([new Date(), new Date()]);
    setStartDate(start_date);
    setEndDate(end_date);
  }, [userSelectedData?.user_id]);

  //Map container property
  const containerStyle = {
    width: "calc(100vw - 615px)",
    height: "calc(100vh - 311px)",
  };

  //Get lat lng for showing path
  const pathLocation = location?.map((locationItem) => {
    return locationItem.map((item) => {
      const latNum = Number(item.latitude);
      const lngNum = Number(item.longitude);
      return { lat: latNum, lng: lngNum };
    });
  });

  //Get First index Lat Lng for showing starting marker point
  const firstIndexLatLng = location?.map((childArray) => {
    const firstIndex = childArray[0];
    return { latitude: firstIndex.latitude, longitude: firstIndex.longitude };
  });

  const lastIndexLatLng = location?.map((childArray) => {
    const lastIndex = childArray[childArray.length - 1];
    return { latitude: lastIndex.latitude, longitude: lastIndex.longitude };
  });

  //Get all let lng for showing time point
  const allLatLngArray = location?.flatMap((locationArray) =>
    locationArray.map((locationObj) => ({
      latitude: parseFloat(locationObj.latitude),
      longitude: parseFloat(locationObj.longitude),
      locationTime: locationObj.location_time,
    }))
  );

  //Get starting and ending lat lng
  const CurrentLocation = location && location[location.length - 1];
  const CurrentLocationLatLng =
    CurrentLocation && CurrentLocation[CurrentLocation.length - 1];
  const currentLatNum = Number(CurrentLocationLatLng?.latitude);
  const currentLngNum = Number(CurrentLocationLatLng?.longitude);

  
  //Marker object moving
  const handleStart = () => {
    if (isRunning) {
      clearInterval(changeId);
      setIsRunning(false);
    } else {
      newSocket.disconnect();
      let i = 0;
      const intervalId = setInterval(() => {
        setProgress({
          lat: allLatLngArray[i]?.latitude,
          lng: allLatLngArray[i]?.longitude,
        });
        setCenter({
          lat: allLatLngArray[i]?.latitude,
          lng: allLatLngArray[i]?.longitude,
        });

        i++;
        if (i === allLatLngArray?.length) {
          clearInterval(intervalId);
          setIsRunning(false);
          setProgress(null);
          setTime("");
        }
      }, 1000);
      setChangeId(intervalId);
      setIsRunning(true);
    }
  };

  //Time marker for showing timestamp
  const timeMarker = {
    url: timeMarkerIcon,
    scaledSize: new window.google.maps.Size(6, 6),
  };

  const endPointMarker = {
    url: endPin,
    scaledSize: new window.google.maps.Size(40, 40),
  };

  //Select Date and filter data according start and end date
  const handleDate = (value) => {
    if (value === null) {
      setSelectDate([new Date(), new Date()]);
      // const start_date = new Date();
      // start_date.setUTCHours(0, 0, 0, 0);
      // const end_date = new Date();
      // end_date.setUTCHours(23, 59, 59, 999);
      // setStartDate(start_date.toISOString());
      // setEndDate(end_date.toISOString());
      const start_date = moment().startOf('day').format();
      const end_date = moment().endOf('day').format();
      setStartDate(start_date);
      setEndDate(end_date)
      return value;
    } else {
      //console.log("value", value);
      setSelectDate(value);
      // const starting_date = value[0];
      // const start_date = new Date(starting_date);
      // const fromDate = new Date(
      //   start_date.getTime() - start_date.getTimezoneOffset() * 60000
      // ).toISOString();
      // setStartDate(fromDate);
      // const ending_date = value[1];
      // const end_date = new Date(ending_date);
      // const toDate = new Date(
      //   end_date.getTime() - end_date.getTimezoneOffset() * 60000
      // ).toISOString();
      // setEndDate(toDate);
      setStartDate(moment(value[0]).startOf('day').format());
      setEndDate(moment(value[1]).endOf('day').format())
    }
  };

  console.log("start",startDate);
  console.log("end",endDate);

  return (
    <div className="map-wrapper">
      <div className="map-heading map-header_size">
        <h5 className="mb-0 pb-0">Employee Location</h5>
        <DateRangePicker
          format="MM-dd-y"
          rangeDivider="-"
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          value={selectDate}
          onChange={(value) => handleDate(value)}
          maxDate={new Date()}
        />
      </div>
      {loading ? (
        <div className="user-location-message">
          <Loader />
        </div>
      ) : (
        <div>
          {location?.length > 0 ? (
            <>
              <GoogleMap
                mapContainerClassName="map-iframe mt-2"
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                options={{
                  streetViewControl: false,
                  disableDefaultUI: true,
                  zoomControl:true
                }}
              >
                {showPlayButton && (
                  <Button className="btn-class" onClick={handleStart}>
                    {isRunning ? (
                      <img src={pause} alt="pause" />
                    ) : (
                      <img src={play} alt="play" />
                    )}
                  </Button>
                )}

                {firstIndexLatLng.map((markerData) => {
                  const latNum = Number(markerData.latitude);
                  const lngNum = Number(markerData.longitude);
                  return (
                    <Marker
                      position={{ lat: latNum, lng: lngNum }}
                      icon={startPin}
                    />
                  );
                })}

                {lastIndexLatLng.map((markerData) => {
                  const latNum = Number(markerData.latitude);
                  const lngNum = Number(markerData.longitude);
                  if (currentLatNum === latNum && currentLngNum === lngNum) {
                    return null;
                  } else {
                    return (
                      <Marker
                        position={{ lat: latNum, lng: lngNum }}
                        icon={endPin}
                      />
                    );
                  }
                })}

                {!isRunning &&
                startDate === start_date &&
                endDate === end_date &&
                userStatus === true ? (
                  <Marker
                    position={{ lat: currentLatNum, lng: currentLngNum }}
                  />
                ) : !isRunning &&
                  startDate !== start_date &&
                  endDate === end_date &&
                  userStatus === true ? (
                  <Marker
                    position={{ lat: currentLatNum, lng: currentLngNum }}
                  />
                ) : (
                  <Marker
                    position={{ lat: currentLatNum, lng: currentLngNum }}
                    icon={endPointMarker}
                    onMouseOver={() => setIsHover("Last location of the user")}
                    onMouseOut={() => setIsHover("")}
                  />
                )}

                {pathLocation &&
                  pathLocation.map((path) => (
                    <Polyline
                      path={path}
                      options={{
                        strokeColor: "#0088FF",
                        strokeWeight: 5,
                        strokeOpacity: 0.8,
                      }}
                    />
                  ))}

                {allLatLngArray &&
                  allLatLngArray.map((userData) => {
                    const inputDateTime = moment(userData.locationTime);
                    const localDateTime = inputDateTime.local();
                    const formattedDateTime = localDateTime.format(
                      "MM-DD-YYYY, h:mm:ss a"
                    );
                    return (
                      <Marker
                        icon={timeMarker}
                        position={{
                          lat: userData.latitude,
                          lng: userData.longitude,
                        }}
                        onMouseOver={() => setTime(formattedDateTime)}
                        onMouseOut={() => setTime("")}
                      />
                    );
                  })}

                {progress && (
                  <Marker
                    position={progress}
                    icon={progressPin}
                  />
                )}

                {time && ( // Display tooltip only when there is selected time
                  <div className="timeMarker">
                    <span className="timeMarkerText">{time}</span>
                  </div>
                )}
                {isHover && (
                  <div className="timeMarker">
                    <span className="timeMarkerText">{isHover}</span>
                  </div>
                )}
              </GoogleMap>
              <div className="location-pin-section">
                <div className="start-marker">
                  <img src={startPin} alt="icon" />
                  <h6>Start location point</h6>
                </div>
                <div className="start-marker">
                  <img src={endPin} alt="icon" />
                  <h6>End location point</h6>
                </div>
                <div className="start-marker">
                  <img src={currentPin} alt="icon" />
                  <h6>Current location point</h6>
                </div>
              </div>
            </>
          ) : (
            <div className="user-location-message">User Location Not Found</div>
          )}
        </div>
      )}
    </div>
  );
};
