import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./table.css";

export const Table = ({ EntryData, columns, SearchBar }) => {
  //Table pagination property
  const options = {
    //custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
  };

  const contentTable = ({ paginationTableProps, paginationProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        columns={columns}
        data={EntryData}
        search={true}
      >
        {(toolkitprops) => (
          <div>
            {SearchBar && (
              <div className="date-container">
                <SearchBar
                  {...toolkitprops.searchProps}
                  placeholder="Search by Employee"
                  className="search-class"
                />
              </div>
            )}
            <div className="attendance-table">
              <BootstrapTable
                keyField={EntryData.user_id}
                striped
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                bordered={false}
                headerClasses="header-class"
                noDataIndication="User attendance not found"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );

  return (
    <div>
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
    </div>
  );
};
