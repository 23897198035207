import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { resetApi } from "../../../../Services/AuthApi/Reset/Reset";
import "./resetPasswordForm.css";
import { PasswordField } from "../../../../Component/InputField/InputField";

export const ResetPasswordForm = () => {

  const params = new URLSearchParams(window.location.search);
  const paramsValue = params.get("token");
  const [loading, setLoading] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const successNotify = (msg) => toast.success(msg);
  const errorNotify = (msg) => toast.error(msg);

  const initialValue = {
    token: paramsValue,
    password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    new_password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters")
      .notOneOf(
        [Yup.ref("password"), null],
        "New password must not be the same as old password"
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const onSubmit = (values) => {
    setLoading(true);
    resetApi(values)
      .then((response) => {
        successNotify(response.data.message);
        setLoading(false);
      })
      .catch((error) => {
        errorNotify(error.response.data.message);
        setLoading(false);
      });
  };
  
  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="create-password">
                <h1>Create new password</h1>
                <p>
                  Your new password must be different from previous used
                  password.
                </p>
              </div>
              <div className="input-container">
                <PasswordField
                  label="Password"
                  name="password"
                  placeholder="Password"
                  showPassword={showPassword}
                  onClick={() => setPassword(!showPassword)}
                />
              </div>
              <div className="input-container">
                <PasswordField
                  label="New Password"
                  name="new_password"
                  placeholder="New Password"
                  showPassword={showNewPassword}
                  onClick={() => setNewPassword(!showNewPassword)}
                />
              </div>
              <div className="input-container">
                <PasswordField
                  label="Confirm new password"
                  name="confirm_password"
                  placeholder="Confirm new password"
                  showPassword={showConfirmPassword}
                  onClick={() => setConfirmPassword(!showConfirmPassword)}
                />
              </div>
              <div className="button-container">
                <Button type="submit" disabled={loading}>
                  {loading && (
                    <Spinner
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {loading ? " loading... " : "Reset"}
                </Button>
              </div>
              <ToastContainer />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
