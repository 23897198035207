import { Form as FormFormik, Formik, ErrorMessage } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Select from "react-select";
import "./editForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import DatePicker from "react-date-picker";
import moment from "moment";
import { genderData, statusData } from "../../../../../Services/Constant";
import { registerUserApi } from "../../../../../Services/User/User";
import { useDispatch } from "react-redux";
import { editEmployee } from "../../../../../Redux/UserSlice";
import Spinner from "react-bootstrap/Spinner";
import { InputField } from "../../../../../Component/InputField/InputField";

export const EditForm = ({ Data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const dobDate = new Date(Data?.dob).toISOString().slice(0, 10);
  const initialValue = {
    user_id: Data?.user_id,
    name: Data?.name,
    gender: Data?.gender,
    address: Data?.address,
    dob: dobDate,
    status: Data?.status,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the name"),
    address: Yup.string().required("Enter the address"),
    dob: Yup.string().required("Select date of birth"),
    status: Yup.string().required("Select status"),
  });

  const onSubmit = (values) => {
    setLoading(true);
    if (JSON.stringify(values) !== JSON.stringify(initialValue)) {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Call register api for edit user
      registerUserApi(formData)
        .then((response) => {
          dispatch(editEmployee(response.data.data));
          toast.success(response.data.message);
          navigate("/employee");
        })
        .catch((errMsg) => {
          console.log(errMsg);
          toast.error(errMsg.response.data.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      navigate("/employee");
    }
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        return (
          <FormFormik>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-4">
                <InputField
                  label="Name"
                  name="name"
                  placeholder="name"
                  type="text"
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <FloatingLabel controlId="floatingSelect" className="mb-3">
                  <div className="select-search add-emp-form">
                    <Select
                      options={genderData}
                      placeholder="Gender"
                      value={
                        values.gender
                          ? genderData.find(
                              (options) => options.value === values.gender
                            )
                          : ""
                      }
                      onChange={(option) =>
                        setFieldValue("gender", option.value)
                      }
                    />
                  </div>
                </FloatingLabel>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="date-picker-container">
                  <label className="floating-label">Date of Birth</label>
                  <DatePicker
                    className="date-picker-class"
                    format="MM-dd-y"
                    dayPlaceholder="DD"
                    value={values.dob}
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    maxDate={new Date()}
                    onChange={(value) => {
                      value === null
                        ? setFieldValue("dob", "")
                        : setFieldValue(
                            "dob",
                            moment(value).format("YYYY-MM-DD")
                          );
                    }}
                  />
                  <ErrorMessage
                    name="dob"
                    render={(errMsg) => (
                      <div className="errorMessage">{errMsg}</div>
                    )}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <InputField
                  label="Address"
                  name="address"
                  placeholder="Address"
                  type="text"
                />
              </div>
              {Data?.role === "user" && (
                <div className="col-12 col-sm-6 col-lg-4">
                  <FloatingLabel controlId="floatingSelect" className="mb-3">
                    <div className="select-search add-emp-form">
                      <Select
                        options={statusData}
                        placeholder="Status"
                        value={
                          values.status
                            ? statusData.find(
                                (options) => options.value === values.status
                              )
                            : ""
                        }
                        onChange={(option) =>
                          setFieldValue("status", option.value)
                        }
                      />
                    </div>
                  </FloatingLabel>
                </div>
              )}
              <div className="col-12">
                <div className="d-flex mt-4 flex-row-reverse">
                  <Button
                    variant="primary"
                    type="submit"
                    className="px-4 mx-1 add-button"
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {loading ? " loading.... " : "Submit"}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="px-4 mx-1"
                    onClick={() => navigate("/employee")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <ToastContainer />
          </FormFormik>
        );
      }}
    </Formik>
  );
};
