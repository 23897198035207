import React from "react";
import "./attendanceGrid.css";

export const AttendanceGrid = ({
  total,
  backgroundColor,
  title,
  grid_icon,
}) => {
  return (
    <div className="d-flex bg-white p-3 mt-3 rounded-3 shadow-container">
      <div className="att-img" style={backgroundColor}>
        <img src={grid_icon} alt="employee" />
      </div>
      <div>
        <h6 className="h6-text">{title}</h6>
        {total ? (
          <h4 className="h4-text mb-0">{total}</h4>
        ) : (
          <h4 className="h4-text mb-0">00</h4>
        )}
      </div>
    </div>
  );
};
