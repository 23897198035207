import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EditForm } from "./EditForm/EditForm";
import "./editEmployee.css";
import { getUserProfile } from "../../../../Services/GetUser/GetUser";
import { Loader } from "../../../../Component/Loader/Loader";

export const EditEmployee = () => {
  const useParamId = useParams();
  const navigate = useNavigate();
  const [editData, setEditData] = useState();
  const [loader, setLoader] = useState(true);

  //Get user details form user profile api
  useEffect(() => {
    getUserProfile(useParamId?.user_id)
      .then((response) => {
        setEditData(response.data.data[0]);
        setLoader(false);
      })
      .catch((errMsg) => console.log(errMsg));
  }, []);

  return (
    <>
      {loader ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="inner-contain">
          <div className="container-fluid">
            <div className="row">
              <div className="d-flex">
                <p
                  className="pe-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/employee")}
                >
                  Employee
                </p>
                {"/"}
                <p className="add ps-1">Employee Update</p>
              </div>
              <h4>Employee Update</h4>
              <div className="col-12 mt-1">
                <div className="edit-container">
                  <EditForm Data={editData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
