import React from "react";
import imageIcon from "../../Assets/Images/image.svg";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./imageInput.css";
export const ImageInput = ({
  imageUrl,
  checkImageValidation,
  handleImageClear,
}) => {
  return (
    <div className="d-flex mt-2 upload-sec">
      <div className="upload-profile">
        {!imageUrl && <div className="upload-profile-img">
          <img src={imageIcon} alt="" className="upload-icon" />
          <p className="mt-2 mb-0 text-center">Upload Photo</p>
        </div>}
        <div className="uploaded-img-set">
          <Field
            name="upload_photo"
            type="file"
            accept=".jpg , .png , .jpeg"
            onChange={(event) => {
              checkImageValidation(event.target.files[0]);
            }}
          />
          {imageUrl && (
            <div>
              <FontAwesomeIcon
                className="font-style"
                icon={faCircleXmark}
                onClick={handleImageClear}
              />
              <img
                src={URL.createObjectURL(imageUrl)}
                alt=""
                className="uploaded-img"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
