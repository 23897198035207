import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import search from "../../Assets/Images/search.svg";

export const SearchBar = ({ value, onChange }) => {
  return (
    <InputGroup>
      <InputGroup.Text id="basic-addon1">
        <img src={search} alt="search" />
      </InputGroup.Text>

      <Form.Control
        type="search"
        placeholder="Search employee"
        aria-label="Recipient's username with two button addons"
        value={value}
        onChange={onChange}
      />
    </InputGroup>
  );
};
