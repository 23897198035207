import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import ChatWelcome from "./WelcomePage/ChatWelcome";
import { ChatRoom } from "./ChatRoom/ChatRoom";
import defaultImage from "../../../Assets/Images/default-img.png";
import "./chat.css";
import {
  chat_socket_event,
  local_socket_url,
  socket_connection_event,
  socket_url,
} from "../../../Services/Socket";
import { SearchBar } from "../../../Component/SearchBar/SearchBar";
import moment from "moment";
import { toast } from "react-toastify";

export const Chat = () => {
  const [searchData, setSearchData] = useState("");
  const [selectedData, setSelectData] = useState("");
  const [userList, setUserList] = useState([]);
  const [chat, setChat] = useState([]);
  const [newSocket, setNewSocket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatLoader, setChatLoader] = useState(true);
  const [notificationCount, setNotificationCount] = useState([]);
  const [chatCurrentMsg, setChatCurrentMsg] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const socket = io(socket_url, {
      transports: ["websocket"],
      secure: true,
      query: {
        Authorization: token,
      },
    });

    setNewSocket(socket);
    //connection event for chat connection
    socket.on(socket_connection_event.connect, () => {
      socket.emit(chat_socket_event.chat_socket_connection);
      socket.emit(chat_socket_event.users_list_request);
      console.log("chat connection established");
    });

    //connection error event
    socket.on(socket_connection_event.connection_error, (error) => {
      if (error.data && error.data.code === "TOKEN_NOT_PROVIDED") {
        console.log("Token not provided");
        setLoading(false);
      } else if (error.data && error.data.code === "INVALID_TOKEN") {
        console.log("Invalid token or expired");
        setLoading(false);
      } else {
        console.log("An error occurred during the connection");
        setLoading(false);
      }
    });

    //event for user list
    socket.on(chat_socket_event.get_users_list, (data) => {
      if (data.status !== 401) {
        socket.emit(chat_socket_event.receiver_notification_web, {
          users_id: data.data.map((item) => item.user_id),
        });
        setUserList(data.data);
        setLoading(false);
      } else {
        socket.disconnect();
        localStorage.clear();
        toast.error(data.message);
        navigate("/");
      }
    });

    socket.on(chat_socket_event.count_receiver_notification_web, (response) => {
      setNotificationCount(response.data);
    });

    socket.on(chat_socket_event.count_seen_chat, (res) => {
      setNotificationCount((pre_count) => [...pre_count, ...res.data]);
    });

    socket.on(chat_socket_event.count_chat_for_receiver, (res) => {
      socket.emit(chat_socket_event.is_open_update_web, {
        is_open: true,
      });
      setNotificationCount((pre_count) => [...pre_count, ...res.data]);
    });

    //user receive chat event
    socket.on(chat_socket_event.receiver_chat, (response) => {
      //console.log("receiver_chat", response);
      setChat((prevChat) => [...prevChat, ...response.data]);
      setChatCurrentMsg((prevChat) => [...prevChat, ...response.data]);
      setLoading(false);
      socket.emit(chat_socket_event.users_list_request);
    });

    socket.on(chat_socket_event.find_users_latest_message, (response) => {
      setChatCurrentMsg(response.data);
    });

    //user send chat event
    socket.on(chat_socket_event.sender_chat, (response) => {
      setChat((prevChat) => [...prevChat, ...response.data]);
      setChatCurrentMsg((prevChat) => [...prevChat, ...response.data]);
      setLoading(false);
    });

    //user old chat event
    socket.on(chat_socket_event.received_old_chat_web, (response) => {
      setChat(response.data);
      setChatLoader(false);
    });

    //disconnect event
    socket.on(socket_connection_event.disconnect, () => {
      console.log("chat disconnected");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    if (event.target.value !== " ") {
      setSearchData(event.target.value);
    }
  };

  const filteredData =
    userList &&
    userList.filter((item) => {
      const fullName = item.name;
      return fullName.toLowerCase().includes(searchData.toLowerCase());
    });

  const handleClick = (data) => {
    if (selectedData.user_id !== data.user_id) {
      setSelectData(data);
      newSocket.emit(chat_socket_event.load_old_chat_web, data.user_id);
      newSocket.emit(chat_socket_event.is_read_update_web, data.user_id, {
        is_read: true,
      });
      setChatLoader(true);
    }
  };

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h4>Chat</h4>
          </div>
          <div className="d-flex">
            <div className="col-4 pe-0">
              <div className="bg-white p-4 mt-2 chat-employee-list">
                <SearchBar value={searchData} onChange={handleSearch} />
                {!loading ? (
                  <div className="emp-chat-list mt-3">
                    {filteredData &&
                      filteredData.map((item) => {
                        return (
                          <div key={item.user_id}>
                            {item.role !== "group_admin" ? (
                              <div>
                                <div
                                  className="emp-chat-box d-flex align-items-center"
                                  style={{
                                    backgroundColor:
                                      selectedData.user_id === item.user_id
                                        ? "#F0FBFB"
                                        : "",
                                  }}
                                  onClick={() => handleClick(item)}
                                >
                                  <div className="emp-img">
                                    {item.upload_photo ? (
                                      <img src={item.upload_photo} alt="" />
                                    ) : (
                                      <img
                                        src={defaultImage}
                                        alt="default_image"
                                      />
                                    )}
                                  </div>

                                  <div className="emp-chat-detail">
                                    <h6
                                      className="d-inline-block text-truncate p-0 m-0"
                                      style={{ maxWidth: 150 }}
                                    >
                                      {item.name}
                                    </h6>
                                    <p
                                      className="d-inline-block text-truncate"
                                      style={{ maxWidth: 150 }}
                                    >
                                      {chatCurrentMsg
                                        .filter(
                                          (user) =>
                                            user.sender_id === item.user_id ||
                                            user.receiver_id === item.user_id
                                        )
                                        .slice(-1).length
                                        ? chatCurrentMsg
                                            .filter(
                                              (user) =>
                                                user.sender_id ===
                                                  item.user_id ||
                                                user.receiver_id ===
                                                  item.user_id
                                            )
                                            .slice(-1)[0].message
                                        : ""}
                                    </p>
                                    {chatCurrentMsg
                                      .filter(
                                        (user) =>
                                          user.sender_id === item.user_id ||
                                          user.receiver_id === item.user_id
                                      )
                                      .slice(-1).length ? (
                                      <p className="current_time">
                                        {moment(
                                          chatCurrentMsg
                                            .filter(
                                              (user) =>
                                                user.sender_id ===
                                                  item.user_id ||
                                                user.receiver_id ===
                                                  item.user_id
                                            )
                                            .slice(-1)[0].created_at
                                        ).format("MM-DD-YYYY") ===
                                        moment(new Date()).format("MM-DD-YYYY")
                                          ? moment(
                                              chatCurrentMsg
                                                .filter(
                                                  (user) =>
                                                    user.sender_id ===
                                                      item.user_id ||
                                                    user.receiver_id ===
                                                      item.user_id
                                                )
                                                .slice(-1)[0].created_at
                                            ).format("h:mm A")
                                          : moment(
                                              chatCurrentMsg
                                                .filter(
                                                  (user) =>
                                                    user.sender_id ===
                                                      item.user_id ||
                                                    user.receiver_id ===
                                                      item.user_id
                                                )
                                                .slice(-1)[0].created_at
                                            ).format("ddd h:mm A")}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className={`user${item?.user_id} chat-notification-count-first`}
                                  ></div>
                                  {notificationCount &&
                                    notificationCount.map((count_data) => {
                                      if (
                                        count_data.sender_id === item.user_id
                                      ) {
                                        if (
                                          count_data.count &&
                                          selectedData?.user_id !==
                                            count_data.sender_id
                                        ) {
                                          document.querySelector(
                                            `.user${item.user_id}`
                                          ).innerHTML = `<div class="chat-notification-count">${count_data.count}</div>`;
                                        } else {
                                          document.querySelector(
                                            `.user${item.user_id}`
                                          ).innerHTML = `<div></div>`;
                                        }
                                      }
                                    })}
                                </div>
                                <hr className="hr-border" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="chat-loader-component">
                    <Spinner animation="border" variant="info" />
                  </div>
                )}
              </div>
            </div>
            {selectedData ? (
              <ChatRoom
                key={selectedData?.user_id}
                selectedData={selectedData}
                socketConnection={newSocket}
                chatData={chat}
                isLoading={chatLoader}
              />
            ) : (
              <ChatWelcome />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
