import axios from "axios"

export const resetApi = (data)=>{
    return(
        axios.post('https://trackerapp-api.sofmen.com/api/resetPassword',data)
    )
}

export const emailVerification =(token)=>{
    return(
        axios.get(`https://trackerapp-api.sofmen.com/api/verifyUpdateEmail?token=${token}`)
    )
}

