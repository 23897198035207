import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import logo from "../../Assets/Images/logo.svg";
import logoutIcon from "../../Assets/Images/logout.png";
import profile_icon from "../../Assets/Images/profile-icon.svg";
import logout_icon from "../../Assets/Images/logout-icon.svg";
import defaultImage from "../../Assets/Images/default-img.png";
import bell from "../../Assets/Images/bell.svg";
import { SideCanvas } from "../Offcanvas/SideCanvas";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { PopupModal } from "../PopupModal/PopupModal";
import toggle_btn from "../../Assets/Images/toggle-btn.svg";
import io from "socket.io-client";
import Badge from "react-bootstrap/Badge";
import { logOutAPi } from "../../Services/AuthApi/Logout/Logout";
import { toast } from "react-toastify";
import {
  local_socket_url,
  notification_socket_event,
  socket_connection_event,
  socket_url,
} from "../../Services/Socket";
import { useSelector } from "react-redux";

const Header = ({ handleOnClick }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userNotificationData, setUserNotificationData] = useState([]);
  const [userNotificationCount, setUserNotificationCount] = useState("");
  const [newSocket, setNewSocket] = useState(null);

  const userData = localStorage.getItem("user");
  const profileData = userData ? JSON.parse(userData) : userData;
  const notificationData = useSelector((state) => state.User.notificationData);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const socket = io(socket_url, {
      transports: ["websocket"],
      secure: true,
      query: {
        Authorization: token,
      },
    });
    setNewSocket(socket);
    socket.on(socket_connection_event.connect, () => {
      socket.emit(notification_socket_event.notification_socket_connection);
      socket.emit("sent_notification_type", {
        type: "all",
      });
      console.log("outer notification server connected");
    });

    socket.on(socket_connection_event.disconnect, () => {
      console.log(" outer notification server disconnected");
    });

    socket.on(notification_socket_event.notification_messages, (response) => {
      if (response.status !== 401) {
        setUserNotificationData(response.data);
        setUserNotificationCount(response.count);
      } else {
        localStorage.clear();
        toast.error(response.message)
        navigate("/");
      }
    });

    socket.on("clear_notification_count", (response) => {
      setUserNotificationCount(response.count);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleShow = () => {
    setShow(true);
    newSocket.emit(notification_socket_event.sent_notification_open, {
      is_open: true,
      notification_message_ids: userNotificationData
        ?.filter((item) => item.is_open === false)
        .map((item) => item.notification_message_id),
    });
  };

  const handleClose = () => {
    setShow(false);
    newSocket.emit(notification_socket_event.sent_notification_open, {
      is_open: true,
      notification_message_ids: notificationData
        ?.filter((item) => item.is_open === false)
        .map((item) => item.notification_message_id),
    });

    newSocket.emit(notification_socket_event.sent_notification_read, {
      notification_message_ids: notificationData
        ?.filter((item) => item.is_read === false)
        .map((item) => item.notification_message_id),
      is_read: true,
    });

    console.log(
      "id",
      notificationData
        ?.filter((item) => item.is_read === false)
        .map((item) => item.notification_message_id)
    );
  };

  const handleLogOut = () => {
    logOutAPi()
      .then((response) => {
        localStorage.clear();
        navigate("/");
      })
      .catch((errMsg) => {
        console.log(errMsg);
        toast.error(errMsg.response.data.message);
      });
  };
 
  return (
    <>
      <div className="navigation">
        <Navbar collapseOnSelect expand="lg">
          <button id="sidebar-toggle" onClick={(e) => handleOnClick(e)}>
            <img src={toggle_btn} />
          </button>
          <Navbar.Brand>
            <img src={logo} alt="logo" />
          </Navbar.Brand>
          <Nav>
            <div className="title header-profile-name">
              <h6 className="p-0 m-0">{profileData?.group_name}</h6>
            </div>
            <div className="navigation-icon" onClick={handleShow}>
              <a href="#">
                <img src={bell} alt="bell" />
                <Badge
                  bg="danger"
                  className="top-0 start-100 translate-middle badge-icon"
                >
                  {userNotificationCount > 0 && userNotificationCount}
                </Badge>
              </a>
            </div>
            <div className="admin-profile-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="profile rounded-circle"
                  id="dropdown-basic"
                >
                  {profileData?.logo_url ? (
                    <img src={profileData?.logo_url} alt="logo" />
                  ) : (
                    <img src={defaultImage} alt="logo" />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("profile")}>
                    <img src={profile_icon} alt="bell" className="mb-1 me-1" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowPopup(true)}>
                    <img src={logout_icon} alt="bell" className="mb-1 me-1" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar>
      </div>
      <SideCanvas
        show={show}
        handleClose={handleClose}
        socket={newSocket}
        notificationData={userNotificationData}
      />
      <PopupModal
        show={showPopup}
        modalTitle={"Logout"}
        description={"Are you sure you want to logout"}
        handleClose={() => setShowPopup(false)}
        photo={logoutIcon}
        button1={"Cancel"}
        button2={"Confirm"}
        handleDelete={handleLogOut}
      />
    </>
  );
};
export default Header;
