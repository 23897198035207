import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadScript } from "@react-google-maps/api";
import io from "socket.io-client";

import { HistoryLocation } from "./HistoryLocation/HistoryLocation";
import { CurrentLocation } from "./CurrentLocation/CurrentLocation";
import { Loader } from "../../../Component/Loader/Loader";
import defaultImage from "../../../Assets/Images/default-img.png";
import { fetchUserData } from "../../../Redux/UserSlice";
import {
  local_socket_url,
  location_socket_event,
  socket_connection_event,
  socket_url,
} from "../../../Services/Socket";
import { SearchBar } from "../../../Component/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Location = () => {
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectSearch, setSelectSearch] = useState("");
  const [currentData, setCurrentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newSocket, setNewSocket] = useState(null);
  const [mapPosition, setMapPosition] = useState({});
  const previousDataRef = useRef(null);

  //Dispatch all user Api
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  //Get all user list
  const data = useSelector((state) => state.User);
  const userData = data.data;

  useEffect(() => {
    const token = localStorage.getItem("token");
    const socket = io(socket_url, {
      transports: ["websocket"],
      secure: true,
      query: {
        Authorization: token,
      },
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setMapPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }

    //currentLocationRef = socket;
    setNewSocket(socket);

    socket.on(socket_connection_event.connect, () => {
      socket.emit(location_socket_event.current_location_socket_connection);
      //console.log("current location connection connect");
    });

    //Connection error
    socket.on(socket_connection_event.connection_error, (error) => {
      if (error.data && error.data.code === "TOKEN_NOT_PROVIDED") {
        console.log("Token not provided");
        setLoading(false);
      } else if (error.data && error.data.code === "INVALID_TOKEN") {
        console.log("Invalid token or expired");
        setLoading(false);
      } else {
        console.log("An error occurred during the connection");
        setLoading(false);
      }
    });

    socket.on(location_socket_event.current_location_update, (response) => {
      const currentLocationData = response.data;
      setLoading(false);
      console.log("current_location_data", response.data);
      if(response.status !== 401){
        if (currentLocationData.length === 0 && previousDataRef.current) {
          setCurrentData(currentLocationData);
          previousDataRef.current = null;
          console.log("data data");
        }
        if (
          JSON.stringify(currentLocationData) !==
            JSON.stringify(previousDataRef.current) &&
          currentLocationData.length > 0
        ) {
          console.log("data not same");
          setCurrentData(currentLocationData);
          previousDataRef.current = currentLocationData; // Update the previousDataRef
        }
      }else{
        socket.disconnect();
        localStorage.clear();
        toast.error(response.message)
        navigate("/");
      }
    });

    socket.on(socket_connection_event.disconnect, () => {
      console.log("current location connection disconnected");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  //Click particular user and get tracking path of user
  const handleTitleClick = (data) => {
    if (selectedUserData?.user_id === data.user_id) {
      setLoading(true);
      newSocket.connect();
      setSelectedUserData(null);
    } else {
      setLoading(false);
      setSelectedUserData(data);
      setCurrentData(null);
      previousDataRef.current = [];
      newSocket.disconnect();
    }
  };

  const handleSearch = (event) => {
    if (event.target.value !== " ") {
      setSelectSearch(event.target.value);
    }
  };

  //filter data according name search 
  const filteredData =
    userData &&
    userData.filter((item) => {
      const fullName = item.name;
      return fullName.toLowerCase().includes(selectSearch.toLowerCase());
    });

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 ">
            <h4>Track Employees </h4>
          </div>
          <div className="col-12 col-lg-4  pe-lg-0">
            <div className="mt-2 bg-white employee-list">
              <SearchBar value={selectSearch} onChange={handleSearch} />
              <div className="emp-contain mt-2">
                {filteredData &&
                  filteredData.map((item) => {
                    return (
                      <div key={item.user_id}>
                        <div
                          className="employee-card-list d-flex"
                          style={{
                            backgroundColor:
                              selectedUserData?.user_id === item.user_id
                                ? "#F0FBFB"
                                : "",
                          }}
                          onClick={() => handleTitleClick(item)}
                        >
                          <div className="emp-img">
                            {item.upload_photo ? (
                              <img src={item.upload_photo} alt="user photo" />
                            ) : (
                              <img src={defaultImage} alt="default-photo" />
                            )}
                          </div>
                          <div className="emp-detail">
                            <h6 className="p-0 m-0 employee-name text-truncate">
                              {item.name}
                            </h6>
                            {item.role === "group_admin" ? (
                              <p>Group admin</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <hr className="hr-border" />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 p-0 ">
            <div className="employee-map-section bg-white ps-4 pt-4 pb-4 mt-2">
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                {loading ? (
                  <div className="col-6 col-lg-8 pt-4 pb-4 mt-2 w-100">
                    <Loader />
                  </div>
                ) : currentData ? (
                  <CurrentLocation
                    data={currentData}
                    mapPosition={mapPosition}
                  />
                ) : (
                  selectedUserData && (
                    <HistoryLocation userSelectedData={selectedUserData} />
                  )
                )}
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
