import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginApi } from "../../../../Services/AuthApi/Login/Login";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import "./loginForm.css";
import {
  InputField,
  PasswordField,
} from "../../../../Component/InputField/InputField";
export const LoginForm = ({ fireBaseToken }) => {
  const errorNotify = (msg) => toast.error(msg);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setPassword] = useState(false);

  const initialValue = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .matches(/\.com$/, "Email must end with .com")
      .required("Email is required"),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  });

  const onSubmit = (values) => {
    setLoading(true);
    loginApi(values, fireBaseToken)
      .then((response) => {
        console.log("response",response.data.data);
        if (response.data.data.role === "group_admin") {
          setData(response);
          navigate("/location");
        } else {
          errorNotify("You don't have the access, please contact Admin");
          setLoading(false);
        }
      })
      .catch((error) => {
        
        errorNotify(error.response.data.message);
        setLoading(false);
      });
  };
  const setData = (response) => {
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.data));
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="create-password">
                <h1>SIGN IN</h1>
                <p>Enter Your credentials to access your account</p>
              </div>
              <div className="input-container">
                <InputField
                  label="Email"
                  name="email"
                  placeholder="email"
                  type="email"
                />
              </div>
              <div className="input-container">
                <PasswordField
                  name="password"
                  label="Password"
                  showPassword={showPassword}
                  placeholder="Password"
                  onClick={() => setPassword(!showPassword)}
                />
              </div>
              <div className="d-flex mt-3 flex-row-reverse">
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("forgot")}
                >
                  Forgot password?
                </p>
              </div>
              <div className="button-container">
                <Button type="submit" disabled={loading}>
                  {loading && (
                    <Spinner
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {loading ? " loading.... " : "SIGN IN"}
                </Button>
              </div>
              <ToastContainer />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
