// // Import the functions you need from the SDKs you need
import { initializeApp} from "firebase/app";
import { getMessaging} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4jtHqrjI0M1SK-4_vTLXe8vNEvRzEFC4",
  authDomain: "trackerapp-18fd2.firebaseapp.com",
  projectId: "trackerapp-18fd2",
  storageBucket: "trackerapp-18fd2.appspot.com",
  messagingSenderId: "932311842750",
  appId: "1:932311842750:web:3c72ba01d4ba778c2383ef",
  measurementId: "G-6SXRQZRE6R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default app;