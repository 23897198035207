import React from 'react'
import { ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export const VerificationModal = ({ show,handleOk}) => {
    return (
        <Modal size='lg' show={show} centered={true}>
            <Modal.Header>
                <Modal.Title>Email address updated</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <p>Confirmation link sent to your email. Once process completed, email address will be updated</p>
            </ModalBody>
            <Modal.Footer>
                <Button onClick={handleOk} className='remove-button-content'>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
