import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { forgotApi } from "../../../../Services/AuthApi/Forgot";
import { useDispatch } from "react-redux";
import { deleteEmail } from "../../../../Redux/UserSlice";
import "./resetForm.css";
import { PasswordField } from "../../../../Component/InputField/InputField";

export const ResetFrom = ({ userEmail }) => {
  const navigate = useNavigate();
  const errorNotify = (msg) => toast.error(msg);
  const successNotify = (msg) => toast.success(msg);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

  const initialValue = {
    email: userEmail,
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Enter the confirm password")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const onSubmit = (values) => {
    setLoading(true);
    forgotApi(values)
      .then((response) => {
        console.log(response);
        successNotify(response.data.message);
        dispatch(deleteEmail());
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        errorNotify(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <div>
              <h1>Create new password</h1>
              <p>
                Your new password must be different from previous used password.
              </p>
            </div>
            <div className="input-container">
              <PasswordField
                label="New Password"
                name="new_password"
                placeholder="New Password"
                showPassword={showNewPassword}
                onClick={() => setNewPassword(!showNewPassword)}
              />
            </div>
            <div className="input-container">
              <PasswordField
                label="Confirm New Password"
                name="confirm_password"
                placeholder="Confirm Password"
                showPassword={showConfirmPassword}
                onClick={() => setConfirmPassword(!showConfirmPassword)}
              />
            </div>
            <div className="button-container">
              <Button type="submit" disabled={loading}>
                {loading && (
                  <Spinner
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {loading ? " loading... " : "RESET"}
              </Button>
            </div>
            <div className="inner-container mt-3">
              <p className="mb-0">Already have an account?</p>
              <h6 className="ms-2 mb-0" onClick={() => navigate("/")}>
                Login
              </h6>
            </div>
            <ToastContainer />
          </Form>
        );
      }}
    </Formik>
  );
};
