import React from "react";
import { FormLayout } from "../../../Component/FormLayout/FormLayout";
import { ResetPasswordForm } from "./ResetPasswordForm/ResetPasswordForm";
export const ResetPassword = () => {
  return (
    <>
      <FormLayout
        headerTitle={"Group reset password"}
        subTitle={"Reset password here fill the details"}
        component={<ResetPasswordForm />}
      />
    </>
  );
};
