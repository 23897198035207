import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Badge from "react-bootstrap/Badge";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";

import edit_1 from "../../../Assets/Images/edit-1.svg";
import subscription from "../../../Assets/Images/subscription.svg";
import charm_circle_tick from "../../../Assets/Images/charm_circle-tick.svg";
import current_plan from "../../../Assets/Images/current_plan.svg";
import calling from "../../../Assets/Images/calling.svg";
import wallet from "../../../Assets/Images/wallet.svg";
import world from "../../../Assets/Images/tabler_world.svg";
import defaultImage from "../../../Assets/Images/default-img.png";
import Button from "react-bootstrap/Button";
import Lock from "../../../Assets/Images/Lock.png";
import "./profile.css";
import { ChangePasswordPopUp } from "./ChangePasswordPopUp/ChangePasswordPopUp";
import { getGroupProfile } from "../../../Services/GetUser/GetUser";
import { toast } from "react-toastify";
import { Loader } from "../../../Component/Loader/Loader";

export const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getGroupProfile()
      .then((response) => {
        setProfileData(response.data.data);
        setLoading(false);
      })
      .catch((errMsg) => {
        console.log(errMsg.response.data.message);
        toast.error(errMsg.response.data.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="inner-contain">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12">
            <h4>Profile</h4>
            {!loading ? (
              <div className="profile-header">
                <div className="profile-img">
                  {profileData?.logo_url ? (
                    <img src={profileData?.logo_url} alt="logo" />
                  ) : (
                    <img src={defaultImage} alt="logo" />
                  )}
                </div>
                <div className="profile-inner-header">
                  <div className="admin-profile-detail">
                    <div className="profile-detail">
                      <h5>{profileData?.group_name}</h5>
                      <h6>{profileData?.email}</h6>
                      <div className="profile-info-call">
                        <div className="d-flex">
                          <span>
                            <img src={calling} alt="calling" />
                          </span>
                          <p className="ms-1 mb-0 me-3 pt-1">
                            {profileData?.phone}
                          </p>
                        </div>

                        <div className="d-flex">
                          <span>
                            <img src={world} alt="website" />
                          </span>
                          <p className="ms-1 mb-0 ">
                            {profileData?.website_url}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="profile-edit-container">
                      <button
                        className="btn edit-profile-admin"
                        onClick={() => navigate("/profile/editProfile")}
                      >
                        <img src={edit_1} alt="edit" />
                        Update
                      </button>
                      <div className="profile-edit-container ">
                        <button
                          className="btn text-center"
                          onClick={() => setShowPopUp(true)}
                        >
                          {" "}
                          <img src={Lock} alt="lock" />
                          Change password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loader">
                <Loader />
              </div>
            )}
            {/* <div className="col-12 mt-3 mt-md-4">
              <div className="profile-header">
                <div className="profile-tabs">
                  <Tabs>
                    <TabList>
                      <Tab>
                        {" "}
                        <img src={subscription} alt="subscription" />
                        Subscription
                      </Tab>
                      <Tab>
                        <img src={wallet} alt="wallet" /> Payment History
                      </Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 p-2 p-xl-3">
                          <div className="basic-subscription">
                            <div className="basic-grey-bg">
                              <h4>
                                <Badge>Basic</Badge>
                              </h4>
                              <h1>
                                $10/<sub>month</sub>
                              </h1>
                              <p>
                                The perfect way to get started and get used to
                                our tools{" "}
                              </p>
                            </div>
                            <div className="basic-subscription-details">
                              <ul>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                              </ul>
                              <button className="btn">
                                <img src={current_plan} className="mb-1 me-2" />
                                Current Plan
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 p-2 p-xl-3">
                          <div className="pro-subscription">
                            <div className="pro-bg">
                              <h4>
                                <Badge>Pro</Badge>
                              </h4>
                              <h1>
                                $50/<sub>month</sub>
                              </h1>
                              <p>
                                The perfect way to get started and get used to
                                our tools{" "}
                              </p>
                            </div>
                            <div className="basic-subscription-details">
                              <ul>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                              </ul>
                              <button className="btn">Upgrade Plan</button>
                            </div>
                          </div>
                        </div>
                        <div className=" col-lg-4 p-2 p-xl-3 subs-box">
                          <div className="Standard-subscription">
                            <div className="basic-Standard-bg">
                              <h4>
                                <Badge>Standard</Badge>
                              </h4>
                              <h1>
                                $25/<sub>month</sub>
                              </h1>
                              <p>
                                The perfect way to get started and get used to
                                our tools{" "}
                              </p>
                            </div>
                            <div className="basic-subscription-details">
                              <ul>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                                <li>
                                  <img src={charm_circle_tick} />
                                  Lorem Ipsum is simply dummy{" "}
                                </li>
                              </ul>
                              <button className="btn">Upgrade Plan</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row">
                        <div className="col-12"></div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ChangePasswordPopUp show={showPopUp} close={() => setShowPopUp(false)} />
    </div>
  );
};
